/**
 * The SubTitle
 */
.subtitle {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: var(--line-height);
    padding: 0 32px;
    color: var(--primary-color);
    border-bottom: 1px solid var(--border-color);
}
.subtitle-top {
    border-top: 1px solid var(--border-color);
}


.subtitle-content {
    display: flex;
    align-items: center;
}
.subtitle-content h3 {
    margin: 0;
    padding: 8px 0;
    font-style: italic;
    font-size: 20px;
    font-weight: normal;
}
.subtitle-content .icon {
    font-size: 22px;
    padding-right: 8px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 950px) {
    .subtitle-top {
        border-top: none;
    }
}

@media screen and (max-width: 500px) {
    .subtitle {
        padding: 0 16px;
    }
}
