/**
 * The Footer
 */
.footer-container {
    min-height: 250px;
    border-top: 3px solid var(--accent-color);
    background-image: url(../../Images/Footer/Background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    color: white;
}
.footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 16px;
}
.footer-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 250px;
}


.footer-links {
    flex-grow: 2;
    display: flex;
    align-items: center;
}
.footer-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
}
.footer-links > div {
    flex-grow: 1;
    font-size: 13px;
}
.footer-links li {
    margin-bottom: 4px;
}


.footer-social.footer-social {
    display: flex;
    margin-top: 16px;
}
.footer-social li {
    margin-right: 16px;
}


.footer-copyright {
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}


.footer-fiscal img {
    width: 50px;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
}
.footer-fiscal:hover img {
    opacity: 1;
}


/**
 * Media Queries
 */
 @media screen and (max-width: 550px) {
    .footer-grid {
        padding: 0 0 32px 0;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
    }
}
@media screen and (max-width: 450px) {
    .footer-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media screen and (max-width: 400px) {
    .footer-wrapper {
        padding: 0 16px 16px 16px;
    }
}
