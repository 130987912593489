/**
 * The Pagination
 */
.pagination.pagination {
    margin: 64px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.pagination li a {
    display: block;
    height: 36px;
    width: 36px;
    line-height: 36px;
    margin: 0 1px;
    text-align: center;
    color: #999;
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
}
.pagination li a:hover {
    background-color: #eee;
}
.pagination-selected.pagination-selected {
    color: black;
}

.pagination-arrow.pagination-arrow {
    font-size: 18px;
}
.pagination-disabled.pagination-disabled {
    opacity: .6;
    pointer-events: none;
}
