/**
 * iOS Padding
 * Padding to avoid the "unsafe" areas behind notches in the screen
 */
:root {
    --safe-area-top: env(safe-area-inset-top, 0px);
    --safe-area-right: env(safe-area-inset-right, 0px);
    --safe-area-bottom: env(safe-area-inset-bottom, 0px);
    --safe-area-left: env(safe-area-inset-left, 0px);
}
body {
    padding: var(--safe-area-top) var(--safe-area-right) var(--safe-area-bottom) var(--safe-area-left);
}
.app .header-container {
    background-color: var(--primary-color);
    padding-top: var(--safe-area-top);
}
.bar-container {
    height: calc(var(--bar-height) + var(--safe-area-bottom));
    padding-bottom: var(--safe-area-bottom);
}
.loader-app {
    margin-top: var(--safe-area-top);
}

@media screen and (max-width: 500px) {
    .dialog-container {
        --dialog-top: var(--safe-area-top);
        --dialog-bottom: var(--safe-area-bottom);
    }
}



/**
 * The App Style
 */
.app {
    --header-height: var(--head-height);
    --bar-height: var(--mobile-height);
    --full-height: calc(100vh - var(--header-height) - var(--bar-height));
}

.app .header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-header);
}
.app .header-search {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.app .searchbar-focused {
    top: var(--safe-area-top);
}
.app .menu-container {
    display: none;
}
.app .header-cart {
    display: block;
}

.app .content-main {
    box-sizing: border-box;
    padding-top: var(--header-height);
    padding-bottom: var(--bar-height);
    min-height: 100vh;
}
.app .header-menu {
    display: none;
}

.app .aside-open {
    top: 0;
}


.app .client-sidebar {
    flex-direction: column;
}
.app .client-subnav,
.app .client-favorite,
.app .client-history {
    display: block;
}
.app .title a {
    display: block;
}
