/**
 * The Fonts Faces
 */
@font-face {
    font-family: "OpenSans";
    src: url("../Fonts/OpenSans/OpenSans-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "OpenSans";
    src: url("../Fonts/OpenSans/OpenSans-Light.ttf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "OpenSans";
    src: url("../Fonts/OpenSans/OpenSans-Semibold.ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "OpenSans";
    src: url("../Fonts/OpenSans/OpenSans-Italic.ttf");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "OpenSansCondensed";
    src: url("../Fonts/OpenSans/OpenSansCondensed-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}



/**
 * The Main Styles
 */
html, body, #root {
    min-height: 100vh;
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    background-color: var(--background-color);
    background-image: url(../Images/Background.jpg);
    background-attachment: fixed;
    background-size: cover;

    -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none;             /* prevent webkit from resizing text to fit */
    -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
}

.no-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.spacing {
    padding: 32px;
}



/**
 * Nothing
 */
.nothing h3 {
    margin: 0;
}
.nothing .btn {
    margin-top: 24px;
}
.nothing-center {
    text-align: center;
}



/**
 * The Font
 */
body,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
select,
textarea {
    font-family: "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}



/**
 * Media Queries
 */
@media screen and (max-width: 600px) {
    .spacing {
        padding: 24px;
    }
}
@media screen and (max-width: 400px) {
    .spacing {
        padding: 16px;
    }
}
