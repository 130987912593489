/**
 * The Categories Content
 */
.categories-content.categories-content {
    background-color: white;
    background-position: bottom right;
    background-repeat: no-repeat;
    padding-bottom: 200px;
    border-bottom: 1px solid var(--border-color);
}
.categories-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 8px;
}



/**
 * The Category Content
 */
.category-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.category-categories {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 32px 16px;
}
.category-categories h3 {
    margin-top: 0;
}
.category-products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    padding-left: 40px;
}
