.alert-container {
    overflow: hidden;
    margin: 0 0 16px 0;
    padding: 0 5px 5px 0;
}
.alert-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    color: white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.5s;
}
    .alert-success .alert-content  {
        background-color: var(--green-color);
    }
    .alert-warning .alert-content  {
        background-color: var(--yellow-color);
    }
    .alert-error .alert-content  {
        background-color: var(--red-color);
    }
    .alert-info .alert-content  {
        background-color: #03a9f4;
    }



.alert-close {
    cursor: pointer;
}
.alert-closing .alert-content {
    transform: translateY(-50px);
}
