/**
 * The Bar
 */
.bar-container {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: var(--bar-height);
    background-color: white;
    border-top: 1px solid var(--border-color);
    z-index: var(--z-bar);
}
.bar-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: var(--bar-height);
}
.bar-list a {
    color: var(--black-color);
}
.bar-selected.bar-selected {
    color: var(--accent-color);
}
.bar-list .link-preicon {
    margin-right: 6px;
}
.bar-list .badge {
    color: white;
    background-color: var(--accent-color);
}

.bar-list .link-menu:hover {
    background-color: transparent;
}



/**
 * Media Queries
 */
@media screen and (max-width: 750px) {
    .bar-list .link-content {
        display: none;
    }
    .bar-list .link-menu {
        width: 32px;
        height: 32px;
        padding: 0;
        text-align: center;
    }
    .bar-list .link-preicon {
        width: 32px;
        margin-right: 0;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
    }
}
