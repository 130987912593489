/**
 * The Title
 */
.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 8px 0 32px 8px;
}
.title a {
    font-size: 24px;
    margin-right: 8px;
}

.title-content {
    display: flex;
    align-items: baseline;
}
.title-content .link {
    flex-shrink: 0;
}
.title-content h2 {
    margin: 0;
    font-size: 26px;
    font-weight: 300;
    color: var(--primary-color);
}
.title-content h3 {
    margin: 0;
    margin-left: 16px;
    font-weight: 200;
    font-size: 20px;
    color: var(--light-color);
}



/**
 * Media Queries
 */
@media screen and (max-width: 500px) {
    .title {
        margin: 8px 0 16px 8px;
    }
    .title-content {
        flex-direction: column;
    }
    .title-content h3 {
        margin-left: 8px;
    }
}

@media screen and (max-width: 500px) {
    .title a {
        margin-right: 8px;
    }
    .title-content h2,
    .title-content h3 {
        margin-left: 0;
    }
    .title-content h2 {
        font-size: 24px;
        line-height: 1;
    }
    .title-content h3 {
        margin-top: 2px;
        font-size: 15px;
    }
}
