/**
 * The Slider
 */
.slider-container {
    position: relative;
    overflow: hidden;
}
.slider-images {
    position: relative;
    width: 300%;
    display: flex;
}
.slider-images > div {
    width: calc(100% / 3);
}

.slider-animate {
    transition: transform ease-in-out 0.3s;
}
.slider-images[data-move="0"] {
    transform: translateX(0%);
}
.slider-images[data-move="1"] {
    transform: translateX(-33.33%);
}
.slider-images[data-move="2"] {
    transform: translateX(-66.66%);
}

.slider-container img {
    display: block;
    max-width: 100%;
    cursor: pointer;
    pointer-events: none;
}



/**
 * Slider Nav
 */
.slider-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
.slider-container:hover .slider-nav {
    opacity: 1;
}
.slider-nav button {
    appearance: none;
    position: absolute;
    top: 50%;
    height: 64px;
    width: 32px;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background-color: transparent;
    color: var(--accent-color);
    font-size: 24px;
    cursor: pointer;
    transition: all .2s;
    pointer-events: all;
    outline: none;
    z-index: 1;
}
.slider-prev.slider-prev {
    left: 0;
}
.slider-next.slider-next {
    right: 0;
}



/**
 * Slider Dots
 */
.slider-dots {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
}
.slider-dots ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.slider-dots button {
    appearance: none;
    display: block;
    height: 6px;
    width: 6px;
    padding: 0;
    margin: 2px 4px;
    background-color: #c6c6c6;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    overflow: hidden;
    text-indent: 100%;
    cursor: pointer;
    outline: none;
    transition: all 0.5s;
}
.slider-active.slider-active {
    transform: scale(2);
    background-color: #fff;
}
