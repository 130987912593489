@font-face {
    font-family: "icomoon";
    src:
        url("../../../Fonts/Icomoon/icomoon.ttf?9i6t86") format("truetype"),
        url("../../../Fonts/Icomoon/icomoon.woff?9i6t86") format("woff"),
        url("../../../Fonts/Icomoon/icomoon.svg?9i6t86#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

.icon:before {
    display: inline-block;
    padding: 0;
    font-family: "icomoon";
    font-weight: 400;
    text-align: center;
}
