/**
 * The Table
 */
.table-container {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid var(--border-color);
    padding: 0 12px;
    color: var(--gray-color);
}
.table-container + .table-container {
    margin-top: 32px;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
}
.table-container thead th {
    padding: 12px 8px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
}
.table-container tfoot th {
    padding: 12px 8px;
    border-top: 1px solid var(--border-color);
}

.table-container td {
    padding: 12px 8px;
}
.table-container td:nth-child(n+2) {
    font-size: 0.9em;
}
.table-container tbody tr:not(:last-child) td {
    border-bottom: 1px solid var(--border-color);
}

.table-container tbody tr {
    cursor: pointer;
    transition: all 0.5s;
}
.table-container tbody tr:hover {
    background-color: rgba(245, 245, 245, 0.8);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .2),
                0 1px 3px 1px rgba(60, 64, 67, .05);
}


.table-none {
    margin: 0;
}



/**
 * Table Cell Classes
 */
.table-big.table-big.table-big {
    font-size: 1em;
}
.table-nowrap.table-nowrap {
    white-space: nowrap;
}

.align-right.align-right {
    text-align: right;
}
.align-center.align-center {
    text-align: center;
}
.align-left.align-left {
    text-align: left;
}

.nowrap {
    white-space: nowrap;
}



/**
 * Media Queries
 */
 @media screen and (max-width: 1000px) {
    .table-container thead,
    .table-container tfoot {
        display: none;
    }
    .table-container tbody tr {
        position: relative;
        display: block;
        padding: 10px 10px 10px 0;
    }
    .table-actions tbody tr {
        padding: 10px 70px 10px 0;
    }
    .table-container tbody tr:not(:last-child) {
        border-bottom: 1px solid rgb(221, 221, 221);
    }
    .table-container tbody td {
        display: inline;
        border: none !important;
    }
    .table-container tbody td:empty {
        display: none;
    }

    .table-break:after {
        content: "\A";
        white-space: pre;
    }
    .table-block.table-block {
        display: block;
    }
    .table-iblock.table-iblock {
        display: inline-block;
    }
    .table-secondary.table-secondary {
        color: rgb(120, 120, 120);
    }
    .table-terciary.table-terciary {
        color: rgb(150, 150, 150);
    }
    .table-hide.table-hide {
        display: none;
    }
    .table-nobr br {
        display: none;
    }

    .table-actions td:last-child {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

@media screen and (max-width: 750px) {
    .table-container tbody tr:hover {
        box-shadow: none;
    }
}
