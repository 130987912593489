/**
 * The Product List
 */
.products-list {
    position: relative;
    margin-bottom: 32px;
}
.products-list-header {
    top: -70px;
    margin-bottom: 0;
}
.products-list-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}



/**
 * Media Queries
 */
 @media screen and (max-width: 1000px) {
    .products-list-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 800px) {
    .products-list {
        box-sizing: border-box;
        width: 100%;
        padding: 0 16px;
    }
    .products-list-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .products-list-header {
        top: 0;
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 650px) {
    .products-list-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .products-list {
        padding: 0 8px;
    }
    .products-list-grid {
        grid-gap: 8px;
    }
}
