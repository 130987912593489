/**
 * Buttons
 */
.btn {
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 16px;
    margin: 0;
    border: none;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    border-radius: 0;
    font-variant: small-caps;
    transition: all 0.5s;
    background: var(--button-background);
    border: 1px solid var(--button-border);

    --button-color: rgb(22, 26, 34);
    --button-shadow: white;
}
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled:active {
    border-color: #ededed;
    background-color: #ededed;
    color: rgba(21, 28, 41, .48);
    box-shadow: none;
    cursor: not-allowed;
}
.btn:hover,
.btn:focus,
.btn:active {
    color: var(--button-color);
    box-shadow: inset 0 0 0 2em var(--button-shadow);
    outline: none;
}
.btn-full {
    width: 100%;
}



/**
 * Button Variants
 */
.btn-primary {
    --button-background: var(--accent-color);
    --button-border: var(--accent-color);
    color: white;
}
.btn-secondary {
    --button-background: var(--subaccent-color);
    --button-border: var(--subaccent-color);
    color: white;
}
.btn-border {
    --button-background: transparent;
    --button-border: rgb(225, 225, 225);
    color: rgb(22, 26, 34);
}
.btn-cancel {
    --button-background: rgb(225, 225, 225);
    --button-border: rgb(225, 225, 225);
    color: rgb(22, 26, 34);
}
.btn-greenblue {
    --button-background: rgb(29, 172, 167);
    --button-border: rgb(29, 172, 167);
    color: white;
}
.btn-error {
    --button-background: var(--red-color);
    --button-border: var(--red-color);
    color: white;
}


.btn:not(.btn-full) + .btn:not(.btn-full) {
    margin-left: 8px;
}
.btn-full + .btn-full {
    margin-top: 8px;
}
