:root {
    --primary-color    : rgb(46, 51, 62);
    --secondary-color  : rgb(56, 65, 82);
    --accent-color     : rgb(195, 38, 33);
    --subaccent-color  : #5336e8;
    --background-color : #ebebeb;
    --border-color     : #e2e3e4;

    --white-color      : rgba(255, 255, 255, 0.8);
    --black-color      : #333;
    --gray-color       : #666;
    --light-color      : #999;
    --lighter-color    : rgb(238, 238, 238);
    --lighter-opacity  : rgba(238, 238, 238, 0.6);
    --lightest-color   : #f0f0f0;
    --lightesty-color  : #f4f4f4;

    --green-color      : rgb(84, 158, 66);
    --yellow-color     : rgb(240, 150, 9);
    --red-color        : rgb(188, 28, 72);

    --app-height       : 100vh;
    --head-height      : 60px;
    --header-height    : 110px;
    --mobile-height    : 50px;
    --bar-height       : 0px;
    --line-height      : 55px;
    --client-height    : 600px;
    --full-height      : calc(var(--app-height) - var(--header-height) - var(--bar-height));

    --z-alert          : 10;
    --z-backdrop       : 50;
    --z-header         : 100;
    --z-bar            : 100;
    --z-navigation     : 200;
    --z-submenu        : 300;
    --z-aside          : 400;
    --z-dialog         : 400;
    --z-loader         : 500;
}



/**
 * Media Queries
 */
@media screen and (max-width: 950px) {
    :root {
        --header-height : var(--head-height);
        --client-height : 0px;
    }
}

@media screen and (max-width: 650px) {
    :root {
        --head-height: var(--mobile-height);
    }
}
