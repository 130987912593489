/**
 * The Mobile Navigation
 */
.navigation-container {
    display: none;
    position: fixed;
    top: var(--header-height);
    width: 100%;
    height: calc(100vh - var(--header-height));
    background-color: white;
    overflow: auto;
    z-index: var(--z-navigation);
}
.navigation-open {
    display: block;
}



/**
 * The Navigation Header
 */
.navigation-header {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 16px 24px;
    background: var(--lighter-opacity);
    color: var(--primary-color);
}
.navigation-header:before {
    content: "";
    position: absolute;
    top: -6px;
    right: 74px;
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: var(--lighter-color);
    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
}

.navigation-header .icon {
    flex-shrink: 0;
    background-color: var(--white-color);
    border: 2px solid var(--accent-color);
    border-radius: 50%;
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 52px;
    text-align: center;
}
.navigation-header > div {
    margin-left: 24px;
}
.navigation-title {
    display: block;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 4px;
}



/**
 * The Navigation Content
 */
.navigation-content {
    background-color: var(--white-color);
}
.navigation-list.navigation-list {
    padding: 8px 0;
}
.navigation-list + .navigation-list {
    border-top: 1px solid var(--border-color);
}
.navigation-list a {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 8px 32px;
    color: #333;
    margin: 0;
}
.navigation-list a:hover {
    background-color: var(--lighter-color);
}
.navigation-list.navigation-list .icon {
    font-size: 2em;
    margin-right: 16px;
    color: var(--accent-color);
}
.navigation-list.navigation-list .icon-right,
.navigation-list.navigation-list .icon-down {
    position: absolute;
    right: 8px;
    top: 55%;
    transform: translateY(-50%);
    font-size: 1.5em;
    color: var(--primary-color);
}

.navigation-list ul a {
    padding-left: 80px;
}



/**
 * Media Queries
 */
 @media screen and (max-width: 500px) {
    .navigation-header:before {
        right: 66px;
    }
}
