/**
 * The Brands Header
 */
.brands-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 230px;
    background-image: url(../../Images/Store/Brands.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.brands-header h2 {
    margin: 0;
    position: relative;
    z-index: 1;
    color: white;
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 5px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}
.brands-header:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
}



/**
 * The Brands Navigation
 */
.brands-nav ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
}
.brands-nav ul a {
    display: block;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 20px;
    color: var(--primary-color);
    transition: background-color 0.2s ease-in-out;
}
.brands-nav ul a:hover {
    background: #ddd;
}
.brands-selected.brands-selected,
.brands-selected.brands-selected:hover {
    background-color: var(--accent-color);
    color: white;
}



/**
 * The Brands Content
 */
.brands-content {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 40px 80px 40px;
}
.brands-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 16px;
}
.brands-grid li a {
    position: relative;
    display: flex;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: 1px solid var(--border-color);
    background-color: white;
    transition: all 0.2s;
}
.brands-grid li a:hover {
    transform: scale(1.1);
    border-color: var(--gray-color);
    z-index: 10;
}
.brands-grid h4 {
    font-size: 14px;
    text-align: center;
}
.brands-grid img {
    display: block;
    width: 100%;
}



/**
 * Media Queries
 */
@media screen and (max-width: 750px) {
    .brands-grid li a:hover {
        transform: none;
        border-color: var(--border-color);
    }
}

@media screen and (max-width: 650px) {
    .brands-grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        grid-gap: 8px;
    }
}

@media screen and (max-width: 600px) {
    .brands-header {
        height: 180px;
    }
    .brands-header h2 {
        font-size: 32px;
    }
}

@media screen and (max-width: 500px) {
    .brands-content {
        padding: 0 16px 16px 16px;
    }
    .brands-nav ul {
        flex-direction: column;
        padding: 16px;
    }
    .brands-nav ul li {
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .brands-content {
        padding: 0 8px 8px 8px;
    }
}
