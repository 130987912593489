/**
 * The Catalog
 */
.catalog-container {
    padding-bottom: 64px;
}
.catalog-content {
    height: 700px;
}
.catalog-content iframe {
    border: none;
    width: 100%;
    height: 700px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 400px) {
    .catalog-container {
        height: 500px;
    }
    .catalog-container iframe {
        height: 500px;
    }
}
