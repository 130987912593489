/**
 * The Notification Container
 */
.notification-container {
    max-width: 600px;
}
.notifcations-none {
    padding: 24px;
}

.notification-wrapper {
    position: relative;
    background-color: var(--lightest-color);
    overflow: hidden;
}
.notification-wrapper:before,
.notification-wrapper:after {
    content: "Eliminar";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--light-color);
}
.notification-wrapper:before {
    left: 16px;
}
.notification-wrapper:after {
    right: 16px;
}



/**
 * The Notification
 */
.notification {
    position: relative;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.3s linear;
    background-color: white;
    z-index: 1;
}
.notification h3 {
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 600;
}
.notification p {
    margin: 0;
    font-size: 12px;
    color: var(--gray-color);
}
.notification:hover {
    background-color: var(--lightest-color);
}


.notification-delete {
    position: absolute;
    top: 4px;
    right: 8px;
    opacity: 0;
    font-size: 12px;
    background-color: var(--lightest-color);
    box-shadow: -10px 6px 22px 10px var(--lightest-color);
    transition: all 0.3s;
    z-index: 1;
}
.notification:hover .notification-delete {
    opacity: 1;
}



/**
 * Notification Lightning
 */
.lightning-primary {
    --lightning-color: var(--accent-color);
}
.lightning-secondary {
    --lightning-color: var(--subaccent-color);
}

.notification-lightning {
    padding: 8px 16px;
}
.notification-lightning-content {
    display: flex;
    justify-content: space-between;
}
.notification-lightning-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}

.notification-lightning-title h4,
.notification-lightning-title h5 {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 8px 12px;
    line-height: 1;
    text-transform: uppercase;
    color: white;
    font-size: 22px;
    background-color: var(--lightning-color);
}
.notification-lightning-title h4 {
    padding-bottom: 0;
    font-weight: 200;
    z-index: 1;
}
.notification-lightning-title h5 {
    margin-top: -4px;
    font-weight: 800;
}

.notification-lightning-timer {
    margin-top: 8px;
    font-size: 14px;
}
.notification-lightning-timer .timer-numbers div {
    padding: 4px 4px;
    margin: 0 1px;
}
.notification-lightning-timer .timer-dots {
    margin: 4px 0 20px 0;
}

.notification-lightning-image {
    box-sizing: border-box;
    height: auto;
    max-width: 180px;
    min-height: 100px;
    max-height: 130px;
    background-color: white;
    cursor: pointer;
}

.notification-container .notification-lightning {
    padding: 16px 32px;
}



/**
 * Notification Promotion
 */
.notification-promotion {
    padding: 8px 16px;
}
.notification-promotion img {
    width: calc(100% - 32px);
    margin: 0 16px;
}

.notification-container .notification-promotion {
    padding: 16px 32px;
}



/**
 * Notification Alert
 */
.notification-alert {
    display: flex;
    align-items: center;
    padding: 16px;
}
.notification-alert .icon {
    font-size: 30px;
    margin-right: 16px;
}

.notification-container .notification-alert {
    padding: 32px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 750px) {
    .notification:hover {
        background-color: white;
    }
    .notification:hover .notification-delete {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .notification-lightning-content {
        flex-direction: column;
    }
    .notification-lightning-header {
        margin: 8px auto 0;
    }
    .notification-lightning-image {
        height: 130px;
        margin: 16px auto 0;
    }
    .notification-promotion img {
        width: 100%;
        margin: 0;
    }
}
