/**
 * The Product Price
 */
.product-price {
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product-price-offer {
    font-size: 0.65em;
    color: #3aa025;
    font-weight: bold;
    line-height: 1;
}
.product-price-offer span {
    font-size: 1.2em;
}
.product-price-old {
    font-size: 0.75em;
    color: var(--light-color);
    line-height: 1;
    text-decoration: line-through;
}
.product-price-new {
    display: flex;
    align-items: center;
}
.product-discount,
.product-discount-name {
    font-size: 0.8em;
    line-height: 1;
    padding-left: 8px;
    color: #39b54a;
}
.product-discount-name {
    font-size: 0.7em;
}


.product-price-symbol {
    font-size: 0.8em;
}
.product-price-new .product-price-symbol {
    margin-right: 4px;
}

.product-semaphore {
    width: 20px;
    height: 20px;
    border: 1px solid var(--border-color);
    border-radius: 50%;
}
