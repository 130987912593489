/**
 * The Error Page
 */
.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: var(--full-height);
}
.error .icon {
    font-size: 50px;
    color: var(--yellow-color);
}
.error h2 {
    color: var(--gray-color);
}
