/**
 * Product Image
 */
.product-image {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
    .product-image > img {
        max-width: 100%;
        max-height: 100%;
        opacity: 0.9;
        pointer-events: none;
    }
    .product-image > h3 {
        margin: 0;
        padding: 0 24px;
        text-align: center;
        font-size: 12px;
    }


.product-image .slider-nav button {
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    opacity: 0;
}
.product-image:hover .slider-nav button {
    opacity: 0.5;
}
    .product-image .slider-nav.slider-nav.slider-nav button:hover {
        opacity: 1;
    }
