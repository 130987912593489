/**
 * The Wrapper
 */
.wrapper {
    box-sizing: border-box;
    max-width: 1248px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
}
.wrapper-spacing {
    padding: 24px;
}
.wrapper-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    min-height: var(--full-height);
    width: 100%;
}



/**
 * Media Queries
 */
@media screen and (max-width: 500px) {
    .wrapper {
        padding-left: 16px;
        padding-right: 16px;
    }
    .wrapper-spacing {
        padding: 16px;
    }
}

@media screen and (max-width: 400px) {
    .wrapper {
        padding-left: 8px;
        padding-right: 8px;
    }
    .wrapper-spacing {
        padding: 8px;
    }
}
