/**
 * The News Content
 */
.news-content {
    border-bottom: 1px solid var(--border-color);
}
.news-image {
    text-align: center;
}
.news-image img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.news-description {
    margin-top: 32px;
}
.news-description p:first-child {
    margin-top: 0;
}
.news-description p:last-child {
    margin-bottom: 0;
}
