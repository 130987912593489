/**
 * The Home Slider
 */
.home-slider {
    --slider-height: 350px;
    height: var(--slider-height);
}
.home-slider img {
    display: block;
    max-width: 100%;
    height: var(--slider-height);
    object-fit: cover;
    margin: 0 auto;
    cursor: pointer;
}

.home-slider .slider-nav button {
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--accent-color);
    font-size: 24px;
    cursor: pointer;
    transition: all .2s;
    pointer-events: all;
    outline: none;
}
.home-slider .slider-nav button:hover {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, .2), 0 1px 3px 0 rgba(0, 0, 0, .1);
}



/**
 * The Home Banner
 */
.home-banner {
    margin-top: 32px;
}



/**
 * The Home Products
 */
.home-products {
    margin: 64px 0 0 0;
}
.home-categories {
    margin: 64px 0 32px 0;
}
.home-categories .home-products {
    margin: 0;
}
.home-others {
    margin: 64px 0 32px 0;
}


.home-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 32px;
}
.home-header h2 {
    color: var(--gray-color);
    font-size: 26px;
    font-weight: 300;
    margin: 0 16px 0 8px;
}
.home-header button {
    white-space: nowrap;
}

.home-products-list {
    display: grid;
    margin-top: 16px;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px;
}



/**
 * The Home Others
 */
.home-others .card {
    display: grid;
    margin-top: 16px;
    margin-bottom: 48px;
    grid-template-columns: repeat(6, 1fr);
}
.home-others .card a {
    box-sizing: border-box;
    height: 200px;
    padding: 16px;
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-color);
    border-right: 1px solid #eae6e6;
    border-bottom: 1px solid #eae6e6;
    border-top: 1px solid white;
    border-left: 1px solid white;
    background-color: white;
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: bottom right;
}
.home-others .card a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
}



/**
 * Media Queries
 */
@media screen and (max-width: 950px) {
    .home-slider {
        --slider-height: 250px;
    }
    .home-products .product-card .product-code {
        padding-left: 16px;
        text-align: left;
    }
}

@media screen and (max-width: 900px) {
    .home-products-list {
        grid-template-columns: repeat(4, 1fr);
    }
    .home-products-list div:nth-child(n+5) {
        display: none;
    }
    .home-others .card {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 750px) {
    .home-slider:hover .slider-nav {
        opacity: 0;
    }

    .home-header {
        margin-top: 64px;
    }
    .home-products-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .home-products-list .product-hover {
        margin-bottom: 0;
        height: auto;
    }
    .home-products-list .product-card {
        max-width: none;
    }
    .product-hover .product-description {
        height: 65px;
    }

    .home-others .card {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 700px) {
    .home-slider {
        --slider-height: 200px;
    }
}

@media screen and (max-width: 600px) {
    .home-slider {
        --slider-height: auto;
    }
    .slider-nav {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .home-banner {
        margin-top: 16px;
    }
    .product-card-image,
    .product-card-image .slider-container {
        height: 150px;
    }

    .home-others .card {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 400px) {
    .home-banner {
        margin-top: 8px;
    }
    .home-products-list {
        margin-top: 8px;
        grid-gap: 8px;
    }
    .product-hover .product-description {
        height: 80px !important;
    }
}
