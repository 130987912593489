/**
 * The Cylinders
 */
.cylinders-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: var(--full-height);
    background-image: url(../../Images/Store/Cylinders.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.cylinders-content h2 {
    margin: 0;
    padding: 8px 0;
    color: white;
    font-family: OpenSansCondensed, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    white-space: nowrap;
}
.cylinders-content select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    width: 100%;
    color: var(--gray-color);
    border: 2px solid white;
    border-radius: 0;
    font-size: 24px;
    font-weight: bold;
    padding: 8px 16px;
    background-color: white;
    background-image: url(../../Images/Select.png);
    background-position: right center;
    background-repeat: no-repeat;
}
.cylinders-content select:focus {
    border-color: var(--accent-color);
    outline: none;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1250px) {
    .cylinders-container {
        --cylinders-width: 160px;
        --cylinders-gap: 16px;
    }
}

@media screen and (max-width: 1150px) {
    .cylinders-content > img {
        max-width: 100%;
        max-height: 400px;
    }
}

@media screen and (max-width: 960px) {
    .cylinders-container {
        --cylinders-width: 140px;
    }
    .cylinders-content {
        padding: 32px;
    }
}

@media screen and (max-width: 800px) {
    .cylinders-content {
        flex-direction: column;
    }
    .cylinders-grid {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .cylinders-content h2 {
        font-size: 32px;
    }
}

@media screen and (max-width: 550px) {
    .cylinders-content h2 {
        white-space: normal;
    }
}

@media screen and (max-width: 400px) {
    .cylinders-container {
        --cylinders-width: 120px;
    }
    .cylinders-content {
        padding: 32px 16px;
    }
}
