/**
 * The About Us Header
 */
.about-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.about-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 300px;
    background-image: url(../../Images/About/Header.jpg);
}
.about-header h2 {
    margin: 0;
    position: relative;
    z-index: 1;
    color: white;
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 5px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}



/**
 * The About Us Nav
 */
.about-nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.about-nav li {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 400px;
    color: white;
    font-size: 32px;
    font-weight: 200;
    letter-spacing: 5px;
    cursor: pointer;
    background-position: top center;
}
.about-nav li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
}

.about-nav-yesterday {
    background-image: url(../../Images/About/YesterdayNav.jpg);
}
.about-nav-today {
    background-image: url(../../Images/About/TodayNav.jpg);
}
.about-nav-tomorrow {
    background-image: url(../../Images/About/TomorrowNav.jpg);
}



/**
 * The About Us Content
 */
.about-section {
    color: white;
    font-size: 18px;
    font-weight: 200;
}
.about-title {
    display: flex;
    width: 100%;
    overflow: hidden;
}
.about-section img {
    display: block;
}
.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;
    min-height: 400px;
    text-align: center;
}


.about-yesterday {
    background-image: url(../../Images/About/YesterdayBG.jpg);
}
.about-yesterday .about-content {
    align-items: flex-end;
}
.about-yesterday h2,
.about-yesterday p {
    width: 50%;
    min-width: 600px;
}
.about-yesterday h2 {
    margin-top: 0;
    font-size: 28px;
    font-weight: 200;
    letter-spacing: 2px;
}
.about-yesterday p:last-child {
    margin-bottom: 0;
}
.about-yesterday .about-title {
    justify-content: flex-end;
}


.about-today {
    background-image: url(../../Images/About/TodayBG.jpg);
}
.about-today .about-content {
    align-items: center;
}
.about-today p {
    width: 70%;
    min-width: 600px;
}


.about-tomorrow {
    background-image: url(../../Images/About/TomorrowBG.jpg);
}
.about-tomorrow .about-content {
    align-items: flex-end;
}
.about-tomorrow p {
    width: 30%;
    min-width: 300px;
}
.about-tomorrow .about-title {
    justify-content: flex-end;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1250px) {
    .about-container {
        padding: 0;
    }
}

@media screen and (max-width: 1100px) {
    .about-section img {
        height: 80px;
    }
}

@media screen and (max-width: 800px) {
    .about-section {
        font-size: 16px;
    }
    .about-content {
        padding: 32px;
    }
    .about-yesterday h2,
    .about-yesterday p,
    .about-today p,
    .about-tomorrow p {
        width: 100%;
        min-width: 0;
    }
    .about-yesterday h2 {
        font-size: 24px;
    }
}

@media screen and (max-width: 600px) {
    .about-header {
        height: 230px;
    }
    .about-header h2 {
        font-size: 32px;
    }
    .about-nav li {
        font-size: 24px;
        letter-spacing: 2px;
    }
    .about-section img {
        height: 60px;
    }
}

@media screen and (max-width: 400px) {
    .about-nav li {
        font-size: 18px;
    }
    .about-content {
        padding: 32px 16px;
    }
    .about-yesterday h2 {
        font-size: 20px;
    }
}
