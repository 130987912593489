/**
 * The Breadcrumb
 */
.breadcrumb li {
    display: inline;
    font-size: 13px;
    text-transform: uppercase;
}
.breadcrumb li a:hover {
    color: var(--black-color);
}
.breadcrumb-chevron span {
    font-weight: 400;
    font-size: 16px;
    vertical-align: middle;
    margin: 0 4px;
}
.breadcrumb-chevron:last-child {
    display: none;
}
