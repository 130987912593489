/**
 * The Query Header
 */
.query-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: var(--line-height);
    padding: 0 32px;
    border-bottom: 1px solid var(--border-color);
    color: var(--gray-color);
}
.query-header b {
    margin-right: 8px;
    color: var(--gray-color);
}
.query-header b:after {
    content: ":";
}



/**
 * The Query Reply
 */
.query-reply-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 2px 8px;
    border-bottom: 1px solid var(--border-color);
    font-size: 12px;
    color: var(--light-color);
}
.query-reply-header label {
    flex-shrink: 0;
    display: block;
    padding: 2px 8px;
    color: white;
    margin-right: 24px;
}
.query-reply-admin {
    background-color: var(--accent-color);
}
.query-reply-client {
    background-color: var(--subaccent-color);
}
.query-reply-header div {
    text-align: right;
}

.query-reply-content {
    padding: 8px 8px 40px;
    color: var(--gray-color);
}
.query-reply-content p {
    margin: 0;
}
.query-reply-content img {
    margin-top: 16px;
    max-width: 400px;
}



/**
 * The Query Reply Form
 */
.query-reply-form {
    border-top: 1px solid var(--border-color);
}
.query-reply-form > form {
    padding: 16px 32px 32px 32px;
}

.query-reply-button {
    text-align: right;
}
.query-reply-btn {
    max-width: 200px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 950px) {
    .query-reply-form {
        margin-top: 32px;
    }
}

@media screen and (max-width: 700px) {
    .query-header {
        padding: 16px 32px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (max-width: 500px) {
    .query-header {
        padding: 16px;
    }
    .query-content.query-content {
        padding: 32px 16px;
    }
    .query-reply.query-reply {
        padding: 0;
    }
    .query-reply-form > form {
        padding: 16px;
    }
}
