/**
 * The Order Header
 */
.order-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: var(--line-height);
    padding: 0 32px;
    border-bottom: 1px solid var(--border-color);
    color: var(--gray-color);
}
.order-header b {
    margin-right: 8px;
    color: var(--gray-color);
}
.order-header b:after {
    content: ":";
}



/**
 * The Order Summary
 */
.order-summary > div {
    box-sizing: border-box;
    border: 1px solid var(--border-color);
}
.order-summary-shipping {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
}
.order-summary-shipping > div {
    width: calc(50% - 16px);
}
.order-summary-shipping > div:first-child {
    margin-right: 32px;
}


.order-summary ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    font-size: 24px;
    font-weight: 200;
    text-align: right;
}
.order-summary ul li:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}
.order-summary ul li h4 {
    margin: 0 16px 0 0;
    font-size: 13px;
    text-align: left;
    color: var(--gray-color);
}
.order-summary ul li p {
    margin: 0;
    white-space: nowrap;
}
.order-summary img {
    max-width: 50px;
}
.order-address.order-address {
    font-size: 15px;
    font-weight: normal;
    white-space: normal;
}



/**
 * The Order Products
 */
.order-products + .order-products {
    padding-top: 64px;
}
.order-table {
    border: none;
    border-bottom: 1px solid var(--border-color);
}
.order-image {
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    border: 1px solid var(--border-color);
}
.order-big.order-big.order-big {
    font-size: 18px;
}
.order-product h4 {
    margin: 0;
}
.order-product h3 {
    margin-top: 8px;
    margin-bottom: 0;
}
.order-campaign.order-campaign {
    color: var(--green-color);
    font-size: 13px;
    margin-bottom: 0;
}
.order-symbol {
    font-size: 0.75em;
    margin-right: 4px;
}



/**
 * The Order Message
 */
.order-message {
    padding-top: 64px;
    padding-bottom: 8px;
}
.order-message p {
    margin: 16px 32px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1000px) {
    .order-table tbody tr {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .order-table-image {
        margin: 0 auto;
    }
    .order-description.order-description {
        grid-column-end: span 2;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
}

@media screen and (max-width: 950px) {
    .order-products {
        padding: 0;
        margin-top: 32px;
    }
    .order-table {
        border: none;
    }
    .order-message {
        margin-top: 32px;
        padding-top: 0;
    }
}

@media screen and (max-width: 700px) {
    .order-summary-shipping {
        flex-direction: column;
    }
    .order-summary-shipping > div {
        width: 100%;
    }
    .order-summary-shipping > div:first-child {
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 500px) {
    .order-header {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
    }
    .order-content.order-content {
        padding: 16px;
    }

    .order-table-image,
    .order-description.order-description {
        grid-column-end: span 3;
    }

    .order-message {
        margin-bottom: 16px;
    }
    .order-message p {
        margin: 16px 16px;
    }
}

@media screen and (max-width: 300px) {
    .order-subtotal {
        grid-column-end: span 2;
    }
    .order-total {
        grid-column-end: span 3;
    }
}
