/**
 * The Download App
 */
.download-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: var(--full-height);
    min-height: 800px;
    padding-bottom: 80px;
    background-image: url(../../Images/Download/Phone.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: auto;
    overflow: hidden;
}



/**
 * The Download Title
 */
.download-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 900px;
    height: 700px;
    max-width: 100vw;
    max-height: 150%;
    font-family: OpenSansCondensed, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.download-title:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 150%;
    background-color: rgba(0, 0, 0, 0.9);
    transform: translate(-50%, -50%) rotate(-35deg);
}
.download-title div {
    position: relative;
    padding: 80px 0 0 40px;
    width: 500px;
    max-width: calc(100% - 80px);
    z-index: 1;
}
.download-title h2 {
    margin: 0;
    line-height: 1;
    font-size: 64px;
    letter-spacing: -1px;
    font-weight: bold;
    font-variant: small-caps;
    color: white;
}
.download-title h2 span {
    color: var(--accent-color);
}
.download-title p {
    font-size: 28px;
    color: var(--light-color);
}



/**
 * The Download Content
 */
.download-content {
    position: relative;
    display: flex;
    padding: 0 16px;
    z-index: 1;
}
.download-content a + a {
    margin-left: 40px;
}
.download-container h3 {
    margin-bottom: 0;
    padding: 0 16px;
    color: var(--gray-color);
    font-size: 20px;
    text-align: center;
}



/**
 * Media Queries
 */
@media screen and (max-width: 900px) {
    .download-container {
        background-image: none;
        min-height: 700px;
    }
}

@media screen and (max-width: 600px) {
    .download-container {
        padding-bottom: 40px;
    }
    .download-title {
        width: 600px;
        height: 500px;
    }
    .download-title:after {
        transform: translate(-50%, -50%) rotate(-20deg);
    }
    .download-title div {
        padding-top: 40px;
    }
    .download-title h2 {
        font-size: 52px;
    }
    .download-title p {
        font-size: 24px;
    }
}

@media screen and (max-width: 500px) {
    .download-container {
        min-height: 600px;
    }
}

@media screen and (max-width: 450px) {
    .download-content {
        flex-direction: column;
        text-align: center;
    }
    .download-content a + a {
        margin-left: 0;
        margin-top: 8px;
    }
    .download-container h3 {
        margin-top: 8px;
    }
}

@media screen and (max-width: 400px) {
    .download-container {
        min-height: 500px;
    }
}
