/**
 * The Timer
 */
 .timer-container {
    display: flex;
    align-items: center;
    margin-top: 24px;
    font-size: 24px;
}
.timer-numbers {
    display: flex;
    justify-content: center;
}
.timer-numbers div {
    padding: 4px 8px;
    margin: 0 2px;
    font-size: 1em;
    color: white;
    background-color: var(--primary-color);
}
.timer-dots {
    margin: 0 2px 20px;
    font-size: 1em;
    line-height: 1;
}
.timer-name {
    margin-top: 4px;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
}
