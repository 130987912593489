/**
 * The Client Container
 */
.client-container {
    display: flex;
    min-height: var(--client-height);
}
.client-sidebar {
    display: flex;
    border-right: 1px solid var(--border-color);
}
.client-card {
    height: 100%;
    min-height: var(--client-height);
}



/**
 * The Client Navigation
 */
.client-nav {
    display: flex;
    flex-direction: column;
}
.client-nav a {
    box-sizing: border-box;
    display: block;
    min-width: 220px;
    color: var(--gray-color);
}
.client-nav > li > a {
    height: var(--line-height);
    padding: 16px 24px;
    border-bottom: 1px solid var(--border-color);
}
.client-nav > li > ul > li > a {
    padding: 8px 24px 8px 48px;
    color: var(--gray-color);
    border-bottom: 1px solid var(--border-color);
}
.client-nav a:hover {
    background-color: var(--lightest-color);
}
.client-selected.client-selected,
.client-selected.client-selected:hover {
    background-color: var(--accent-color);
    color: white;
}


.client-sub:after {
    position: absolute;
    top: 50%;
    right: 8px;
    content: "\e906";
    font-family: "icomoon";
    font-weight: 400;
    color: var(--gray-color);
    transform: translateY(-50%);
}
.client-down:after {
    content: "\e902";
}
.client-selected.client-sub:after {
    color: white;
}


.client-subnav {
    display: none;
    margin-top: 32px;
}
.client-favorite,
.client-history {
    display: none;
}


.client-content {
    flex: 1;
}
.client-blank {
    height: 100%;
    background-color: white;
    background-image: url(../../Images/Client.jpg);
    background-repeat: no-repeat;
    background-position: center;
}



/**
 * Client Account
 */
.client-warning {
    color: var(--yellow-color);
    margin-top: 0;
    margin-bottom: 40px;
}
.client-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
}
.client-fields label {
    margin-bottom: 0;
}
.client-double {
    grid-column-end: span 2;
}
.client-triple {
    grid-column-end: span 3;
}
.client-btn {
    grid-column-start: 3;
    display: flex;
    align-items: flex-end;
}



/**
 * Client Address
 */
.client-address {
    margin-top: 32px;
    text-align: right;
}



/**
 * Client Header
 */
.client-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: var(--line-height);
    padding: 0 32px;
    border-bottom: 1px solid var(--border-color);
}



/**
 * Media Queries
 */
@media screen and (max-width: 950px) {
    .client-container {
        min-height: 0;
    }

    .client-header {
        padding: 0 16px;
    }
    .client-header a {
        display: block;
    }
    .client-menuonly .client-header a {
        display: none;
    }
    .client-menuonly .client-container {
        min-height: 0;
    }

    .client-account {
        margin-bottom: 32px;
    }
    .client-fields {
        grid-template-columns: repeat(2, 1fr);
    }
    .client-double {
        grid-column-end: span 1;
    }
    .client-triple {
        grid-column-end: span 2;
    }
    .client-btn {
        grid-column-start: 2;
    }

    .client-sidebar {
        display: none;
    }
    .client-menuonly .client-container {
        display: block;
    }
    .client-menuonly .client-sidebar {
        border-right: none;
    }
    .client-menuonly .client-sidebar {
        display: block;
        width: 100%;
    }
    .client-menuonly .client-content {
        display: none;
    }

    .client-table.client-table {
        padding: 0;
    }
    .client-table .table-container {
        border-top: none;
        border-right: none;
        border-left: none;
    }
    .client-table .table-none {
        padding: 16px 32px;
    }
    .client-table .pagination {
        margin-top: 32px;
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 650px) {
    .queries-header {
        padding: 8px 16px;
    }
    .queries-header > div {
        width: 100%;
    }
    .queries-header.queries-header .btn + .btn {
        margin-top: 8px;
        margin-left: 0;
    }
    .client-create {
        text-align: right;
    }
}

@media screen and (max-width: 500px) {
    .client-title h3 {
        display: none;
    }

    .client-fields {
        grid-template-columns: repeat(1, 1fr);
    }
    .client-triple {
        grid-column-end: span 1;
    }
    .client-btn {
        grid-column-start: 1;
    }

    .queries-header {
        flex-direction: column;
        padding: 8px 16px 16px 16px;
    }
    .queries-header .btn.btn.btn {
        box-sizing: border-box;
        margin: 8px 0 0 0;
        width: 100%;
    }
}
