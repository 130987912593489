/**
 * The Main Header
 */
.header-container {
    background-color: var(--primary-color);
    color: white;
}

.header-content {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--head-height);
}
.header-content h1 {
    margin: 0;
}
.header-content h1 img {
    display: block;
    height: 30px;
}

.header-secondary {
    display: flex;
    flex-grow: 2;
    align-items: center;
}

.header-phone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.header-phone p {
    margin: 0 8px;
    font-weight: 200;
}

.header-cart .badge {
    color: white;
    background-color: var(--accent-color);
}
.header-selected.header-selected {
    color: var(--accent-color);
}



/**
 * The Search Bar
 */
.searchbar-container {
    position: relative;
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 8px 0 32px;
    padding: 4px 16px;
    background-color: var(--secondary-color);
    transition: all 0.2s linear;
}
.searchbar-input.searchbar-input {
    flex-grow: 1;
    height: 28px;
    background: transparent !important;
    border: none;
    font-size: 14px;
    padding: 0;
    color: white;
}
.searchbar-input.searchbar-input:focus {
    outline: none;
}
.searchbar-input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}
.searchbar-input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5);
}
.searchbar-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.searchbar-bar {
    flex-shrink: 0;
    width: 1px;
    height: 24px;
    margin: 2px 8px;
    background-color: rgba(255, 255, 255, 0.3);
}
.searchbar-back {
    display: none;
}

.searchbar-results {
    position: absolute;
}



/**
 * The Header Icons
 */
.header-icon {
    color: white;
}
.header-icon .icon {
    font-size: 20px;
    line-height: 20px;
}

.header-menu,
.header-cart {
    display: none;
    margin-left: 8px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 950px) {
    .header-phone {
        display: none;
    }
    .header-menu,
    .header-cart {
        display: block;
    }
    .searchbar-container {
        margin-left: 16px;
        margin-right: 0;
    }
    .searchbar-focused {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        z-index: 1;
    }
}

@media screen and (max-width: 750px) {
    .header-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: var(--z-header);
    }
}

@media screen and (max-width: 650px) {
    .header-content h1 img {
        height: 24px;
    }
    .header-secondary {
        flex-grow: 0;
    }
    .searchbar-container {
        padding: 0;
        flex-grow: 0;
        background-color: transparent;
    }
    .searchbar-input,
    .searchbar-bar {
        display: none;
    }

    .searchbar-focused.searchbar-container {
        padding: 4px 16px;
        background-color: var(--secondary-color);
    }
    .searchbar-focused .searchbar-input,
    .searchbar-focused .searchbar-bar,
    .searchbar-focused .searchbar-back {
        display: block;
    }
}

@media screen and (max-width: 400px) {
    .header-content {
        padding-left: 16px;
        padding-right: 16px;
    }
    .header-content h1 img {
        height: 26px;
    }
}

@media screen and (max-width: 320px) {
    .header-content h1 img {
        height: 22px;
    }
}

