/**
 * Number Field
 */
.number-field {
    position: relative;
    display: flex;
    width: 140px;
    margin: 0 auto;
    height: 55px;
}


.number-field button {
    appearance: none;
    box-sizing: border-box;
    flex-shrink: 0;
    display: block;
    width: 32px;
    padding: 0;
    min-width: 0;
    font-size: 24px;
    font-weight: 200;
    color: var(--light-color);
    border: 2px solid var(--border-color);
    background-color: white;
    cursor: pointer;
    transition: all 0.2s;
    z-index: 1;
}
.number-field .number-dec {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.number-field .number-inc {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.number-field button:focus {
    outline: none;
    color: var(--accent-color);
    border-color: var(--accent-color);
}
.number-field button:hover {
    color: var(--black-color);
}


.number-field input[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
    width: calc(100% - 64px);
    padding: 0;
    text-align: center;
    font-size: 18px;
    font-weight: 200;
    color: var(--gray-color);
    background-color: white;
    border: 2px solid var(--border-color);
    border-radius: 0;
    margin-left: -2px;
    margin-right: -2px;
    transition: all 0.2s;
    z-index: 0;
}
.number-field input[type="number"]:focus {
    outline: none;
    color: var(--accent-color);
    border-color: var(--accent-color);
    z-index: 2;
}

.number-field input[type="number"]::-webkit-outer-spin-button,
.number-field input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
