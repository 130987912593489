/**
 * The Product Dialog
 */
.product-dialog-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--black-color);
}
.product-dialog-favorite {
    position: absolute;
    top: 32px;
    right: 24px;
}

.product-dialog-image {
    flex-shrink: 0;
    width: 220px;
    height: 220px;
    border: 1px solid var(--border-color);
}


.product-dialog-info {
    flex-grow: 1;
    margin-left: 32px;
}
.product-dialog-info h4 {
    margin-top: 0;
    margin-bottom: 8px;
    color: var(--light-color);
    font-weight: 400;
}
.product-dialog-info h3 {
    font-weight: 400;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 0;
}

.product-dialog-price {
    font-size: 32px;
}
.product-dialog-price .product-price-offer {
    font-size: 0.5em;
}
.product-dialog-price .product-price-old {
    font-size: 0.5em;
}
.product-dialog-price .product-discount {
    font-size: 0.6em;
    padding-left: 16px;
}

.product-dialog-discounts.product-dialog-discounts {
    display: flex;
    font-size: 12px;
    margin-top: 16px;
    text-align: center;
}
.product-dialog-discounts li {
    padding: 8px;
    border: 1px solid white;
    cursor: pointer;
}
.product-dialog-discounts li:hover,
.product-dialog-selected.product-dialog-selected {
    border-color: var(--border-color);
}



/**
 * The Product Footer
 */
.product-dialog-footer {
    text-align: center;
    border-top: 1px solid var(--border-color);
}

.product-dialog-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}
.product-dialog-amount h3 {
    margin: 0 16px 0 0;
    font-size: 20px;
    color: var(--gray-color);
}
.product-dialog-amount .number-field {
    margin: 0;
}
.product-dialog-button {
    display: block;
    max-width: 300px;
    margin: 0 auto 16px;
}

.product-success.product-success.product-success {
    font-size: 18px;
    color: var(--green-color);
}



/**
 * Media Queries
 */
@media screen and (max-width: 650px) {
    .product-price-new {
        flex-direction: column;
    }
    .product-dialog-info {
        margin-top: 24px;
        margin-left: 0;
    }
}

@media screen and (max-width: 550px) {
    .product-dialog-content {
        flex-direction: column;
        margin-left: 0;
    }
}
