/**
 * The Auth Content
 */
.auth-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    color: rgba(21, 28, 41, .48);
}

.auth-content {
    min-height: 100vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.auth-terms {
    flex-shrink: 1;
    margin: 0;
    padding: 24px;
    text-align: center;
    font-size: 0.8em;
}



/**
 * Auth Main
 */
.auth-main {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
}
.auth-main h1 {
    margin: 0;
}
.auth-main h2 {
    font-weight: lighter;
}
.auth-main img {
    max-width: 100%;
}

.auth-form {
    width: 390px;
    max-width: 100%;
}
.auth-recover {
    margin: 32px 0 0;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
}



/**
 * Auth Info
 */
.auth-info {
    position: relative;
    box-sizing: border-box;
    min-height: 100vh;
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0 80px;
    color: white;
    background-image: url(../../Images/AuthBG.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
}
.auth-info:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.auth-info ul {
    position: relative;
    z-index: 1;
    max-width: 400px;
}
.auth-info li {
    display: flex;
}
.auth-info li:not(:last-child) {
    padding-bottom: 32px;
}

.auth-icon {
    background-color: white;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    color: #9b9aa0;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}
.auth-info p {
    margin: 8px 0 0 0;
    font-weight: lighter;
}


/**
 * The Auth Recover
 */
.auth-dialog .dialog-content {
    display: block;
    color: var(--black-color);
}
.auth-dialog h3 {
    margin-top: 0;
}



/**
 * Media Queries
 */
@media screen and (max-width: 800px) {
    .auth-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 64px;
        background-image: url(../../Images/AuthBG.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
    }
    .auth-container:after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .auth-content {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        min-height: 0;
        padding: 32px;
        background-color: var(--white-color);
        z-index: 2;
    }
    .auth-terms {
        padding: 32px 0 0 0;
    }
    .auth-info {
        box-sizing: border-box;
        min-height: 0;
        background-image: none;
        width: 100%;
        padding: 64px;
    }
    .auth-info:after {
        display: none;
    }
}
@media screen and (max-width: 500px) {
    .auth-main {
        padding: 0;
    }
    .auth-info {
        padding: 64px 32px;
    }
}
@media screen and (max-width: 400px) {
    .auth-content {
        padding: 32px;
    }
}

