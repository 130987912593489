/**
 * The Benefits
 */
.benefits-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 32px;
}

.benefits-container div {
    margin-right: 32px;
    width: 500px;
}
.benefits-container h2 {
    max-width: 400px;
    margin-top: 0;
    font-family: OpenSansCondensed, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 40px;
    letter-spacing: -1px;
}
.benefits-container p {
    margin: 0;
    font-size: 20px;
    height: 80px;
}
.benefits-container svg {
    flex-shrink: 0;
}



.benefits-item {
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}
.benefits-item:hover {
    opacity: 0.5;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1200px) {
    .benefits-container {
        width: 100%;
    }
    .benefits-container > div {
        width: 100%;
        flex-grow: 2;
    }
}

@media screen and (max-width: 1000px) {
    .benefits-container {
        padding: 32px;
    }
}

@media screen and (max-width: 900px) {
    .benefits-container {
        flex-direction: column;
        padding: 16px;
        width: 520px;
    }
    .benefits-container > div {
        box-sizing: border-box;
        margin: 0;
        padding: 32px;
    }
    .benefits-container svg {
        margin-top: 0;
        max-width: 100%;
        height: auto;
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 600px) {
    .benefits-container {
        padding: 0;
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .benefits-container h2 {
        font-size: 32px;
    }
    .benefits-container p {
        font-size: 16px;
    }
}
