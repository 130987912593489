/**
 * Input Field
 */
.input-field {
    display: block;
}
.input-field p {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 12px;
    color: rgb(155, 154, 160);
    pointer-events: none;
    transition: all 0.2s;
}
.input-required p:after {
    content: "*";
    margin-left: 5px;
}
.input-file p {
    margin-bottom: -8px;
}
.input-margin {
    margin-bottom: 32px;
}


.input-transform {
    transform-origin: top left;
    transform: translateY(20px) scale(1.2);
}
.input-value.input-value {
    transform: translateY(0) scale(1);
}
.input-focused.input-focused {
    color: var(--accent-color);
}


.input-helper.input-helper {
    font-size: 0.9em;
}



/**
 * Input Error
 */
.input-error p {
    color: red;
}
.input-error.input-error input[type="text"],
.input-error.input-error input[type="email"],
.input-error.input-error input[type="tel"],
.input-error.input-error input[type="number"],
.input-error.input-error input[type="password"],
.input-error.input-error select,
.input-error.input-error textarea {
    border-color: red;
}



/**
 * Inputs, Selects & Textareas
 */
@keyframes show-border {
    to { background-size: 100% 100%; }
}
@keyframes hide-border {
    from { background-size: 100% 100%; }
}

@keyframes show-select {
    to { background-size: auto, 100% 100%; }
}
@keyframes hide-select {
    from { background-size: auto, 100% 100%; }
}

.input-field input[type="text"],
.input-field input[type="email"],
.input-field input[type="tel"],
.input-field input[type="number"],
.input-field input[type="password"],
.input-field select,
.input-field textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    box-sizing: border-box;
    font-size: 14px;
    color: var(--gray-color);
    border: none;
    background-color: white;
    padding: 4px 8px;
    border-bottom: solid 1px rgb(155, 154, 160);
    border-radius: 0;
    animation: hide-border 0.1s cubic-bezier(.84, .09, .47, 1) both;
}
.input-field input[type="text"],
.input-field input[type="email"],
.input-field input[type="tel"],
.input-field input[type="number"],
.input-field input[type="password"],
.input-field textarea {
    background: linear-gradient(to top, var(--accent-color) 2px, transparent 2px) !important;
    background-size: 0 100% !important;
    background-repeat: no-repeat !important;
}

.input-field input[type="text"]:focus,
.input-field input[type="email"]:focus,
.input-field input[type="tel"]:focus,
.input-field input[type="number"]:focus,
.input-field input[type="password"]:focus,
.input-field select:focus,
.input-field textarea:focus {
    box-shadow: none;
    outline: none;
    border-color: var(--accent-color);
    animation: show-border 0.3s cubic-bezier(.84, .09, .47, 1) both;
}
.input-field select:focus {
    animation: show-select 0.3s cubic-bezier(.84, .09, .47, 1) both;
}

.input-field input[type="text"]:disabled,
.input-field input[type="email"]:disabled,
.input-field input[type="tel"]:disabled,
.input-field input[type="number"]:disabled,
.input-field input[type="password"]:disabled,
.input-field select:disabled,
.input-field textarea:disabled {
    background-color: rgb(245, 245, 245);
    background: linear-gradient(to top, transparent 2px, rgb(245, 245, 245) 2px);
    color: rgb(175, 175, 175);
    border-color: rgb(205, 205, 205);
}

.input-field input[type="text"],
.input-field input[type="email"],
.input-field input[type="tel"],
.input-field input[type="password"],
.input-field input[type="number"],
.input-field textarea,
.input-field select {
    width: 100%;
    margin: 0;
}
.input-field textarea {
    height: 55px;
}

.input-field select {
    text-indent: 0.01px;
    margin: 0;
    padding: 4px 24px 4px 8px;
    border-radius: 0;
    background-color: transparent;
    background-image: url(../../../Images/Select.png),
        linear-gradient(to top, var(--accent-color) 2px, transparent 2px);
    background-position: right center, 0 0;
    background-size: auto, 0 100%;
    background-repeat: no-repeat;
    animation: hide-select 0.1s cubic-bezier(.84, .09, .47, 1) both;
}
.input-field select::-ms-expand {
    display: none;
}


.input-field input:-webkit-autofill,
.input-field textarea:-webkit-autofill,
.input-field select:-webkit-autofill {
    background-color: transparent !important;
    background: linear-gradient(to top, var(--accent-color) 2px, transparent 2px) !important;
    background-size: 0 100% !important;
    background-repeat: no-repeat !important;
    color: rgb(0, 0, 0) !important;
}



/**
 * File Input
 */
.file-container.file-container {
    display: flex;
}
.file-input.file-input {
    flex-grow: 2;
    display: flex;
    align-items: flex-end;
    padding: 4px 8px;
    border-bottom: solid 1px rgb(155, 154, 160);
    cursor: pointer;
}
.file-box {
    position: relative;
    overflow: hidden;
    display: inline;
    flex-grow: 0;
    flex-shrink: 0;
}
    .file-box input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        opacity: 0;
        pointer-events: none;
    }



/**
 * Checkbox & Radio
 */
.checkbox-container label:first-child {
    margin-right: 24px;
}

.input-field input[type="checkbox"],
.input-field input[type="radio"] {
    width: 12px;
    height: 12px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    opacity: 0;
}
.input-field label input[type="checkbox"] + span,
.input-field label input[type="radio"] + span {
    position: relative;
    display: inline-block;
    margin: 2px 5px -2px -12px;
    height: 10px;
    width: 10px;
    border: 1px solid rgb(155, 154, 160);
    border-radius: 0;
    font-size: 1px;
    vertical-align: baseline;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}
    .input-field label input[type="radio"] + span {
        border-radius: 100%;
    }

    .input-field label input[type="checkbox"]:not(:disabled) + span:hover,
    .input-field label input[type="radio"]:not(:disabled) + span:hover,
    .input-field label input[type="checkbox"]:focus + span,
    .input-field label input[type="radio"]:focus + span {
        border-color: rgb(27, 127, 204);
        box-shadow: 0 0 8px rgba(27, 127, 204, 0.6);
        outline: none;
    }
    .input-field label input[type="checkbox"]:checked + span,
    .input-field label input[type="radio"]:checked + span {
        border-color: transparent !important;
    }

    .input-field label input[type="checkbox"]:checked + span:after {
        content: "✓";
        position: absolute;
        top: -10px;
        left: 2px;
        font-size: 18px;
        animation: tick 0.4s cubic-bezier(0.175, 0.885, 0.320, 1.275);
    }
    .input-field label input[type="radio"]:checked + span {
        background-color: var(--accent-color);
        border-color: var(--accent-color);
    }

    .input-field label input[type="checkbox"]:disabled + span,
    .input-field label input[type="radio"]:disabled + span {
        background-color: rgb(245, 245, 245);
        color: rgb(175, 175, 175);
    }
