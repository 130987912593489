
.icon-history:before {
  content: "\e926";
}
.icon-unfavorite:before {
  content: "\e924";
}
.icon-favorite:before {
  content: "\e925";
}
.icon-image:before {
  content: "\e923";
}
.icon-map:before {
  content: "\e922";
}
.icon-catalog:before {
  content: "\e920";
}
.icon-contact:before {
  content: "\e91f";
}
.icon-filter:before {
  content: "\e91b";
}
.icon-back:before {
  content: "\e91a";
}
.icon-brand:before {
  content: "\e917";
}
.icon-offer:before {
  content: "\e918";
}
.icon-category:before {
  content: "\e919";
}
.icon-menu:before {
  content: "\e915";
}
.icon-money:before {
  content: "\e913";
}
.icon-warning:before {
  content: "\e912";
}
.icon-location:before {
  content: "\e911";
}
.icon-shipping:before {
  content: "\e910";
}
.icon-delete:before {
  content: "\e90f";
}
.icon-address:before {
  content: "\e90e";
}
.icon-close:before {
  content: "\e90d";
}
.icon-query:before {
  content: "\e90c";
}
.icon-logout:before {
  content: "\e90b";
}
.icon-voucher:before {
  content: "\e909";
}
.icon-bank:before {
  content: "\e90a";
}
.icon-left:before {
  content: "\e908";
}
.icon-cart:before {
  content: "\e907";
}
.icon-right:before {
  content: "\e906";
}
.icon-down:before {
  content: "\e902";
}
.icon-notifications:before {
  content: "\e903";
}
.icon-phone:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e905";
}
.icon-product:before {
  content: "\e900";
}
.icon-account:before {
  content: "\e901";
}
.icon-about:before {
  content: "\e91e";
}
.icon-bike:before {
  content: "\e916";
}
.icon-whatsapp:before {
  content: "\e921";
}
.icon-terms:before {
  content: "\e91d";
}
.icon-benefits:before {
  content: "\e91c";
}
.icon-news:before {
  content: "\e914";
}
