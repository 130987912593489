/**
 * The Cart
 */
.cart-header {
    display: flex;
    align-items: baseline;
}
.cart-header h3 {
    margin: 0;
    margin-left: 16px;
    font-weight: 200;
    font-size: 20px;
    color: var(--light-color);
}

.cart-container {
    display: flex;
}
.cart-content {
    flex-grow: 1;
}



/**
 * The Cart Content
 */
.cart-card + .cart-card {
    margin-top: 24px;
}
.cart-units {
    padding: 16px 24px;
    border-bottom: 1px solid var(--border-color);
}
.cart-units p {
    margin: 0;
}
    .cart-discount {
        color: var(--green-color);
    }
    .cart-next {
        color: var(--yellow-color);
    }



/**
 * The Cart Table
 */
.cart-table {
    width: 100%;
    border-collapse: collapse;
}
.cart-table tr {
    border-bottom: 1px solid var(--border-color);
}
.cart-table td {
    padding: 16px 0 16px 16px;
}
.cart-table td:last-child {
    padding-right: 16px;
}

.cart-image {
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    border: 1px solid var(--border-color);
}
.cart-product {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
    .cart-product h4 {
        margin-top: 0;
        margin-bottom: 8px;
        color: var(--light-color);
        font-weight: 400;
    }
    .cart-product h3 {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        font-weight: 400;
    }
    .cart-stock {
        margin-top: 8px;
        margin-bottom: 0;
        color: var(--yellow-color);
    }


.cart-column {
    height: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
}
    .cart-column h4 {
        margin: 0 0 8px 0;
        font-size: 13px;
        color: var(--gray-color);
    }
    .cart-column > div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .cart-amount a {
        margin-top: 8px;
        font-size: 12px;
    }

    .cart-price.cart-price {
        flex-direction: row;
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 200;
        white-space: nowrap;
    }
    .cart-price .product-price-old {
        text-align: right;
    }
    .cart-symbol {
        font-size: 0.75em;
        margin-right: 4px;
    }



/**
 * The Cart Shipping
 */
.cart-shipping {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.cart-shipping li {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: var(--gray-color);
    filter: grayscale(1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.cart-shipping div {
    width: 158px;
    height: 153px;
}
.cart-shipping1 {
    background-image: url(../../Images/Shipping/1.png);
}
.cart-shipping2 {
    background-image: url(../../Images/Shipping/2.png);
}
.cart-shipping3 {
    background-image: url(../../Images/Shipping/3.png);
}
.cart-shipping p {
    margin: 0;
}
.cart-shipping li:hover,
.cart-selected.cart-selected {
    filter: grayscale(0);
    color: var(--black-color);
}



/**
 * The Cart Address
 */
.cart-address-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cart-address-content > .icon {
    font-size: 24px;
    color: var(--accent-color);
    margin-right: 24px;
}
.cart-address-content > a {
    font-size: 16px;
}
.cart-address-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.cart-address-current h3 {
    margin: 0;
}
.cart-address-current ul li {
    display: inline;
    color: var(--gray-color);
}
.cart-address-current ul li:not(:last-child):after {
    content: "-";
    margin: 0 6px;
}



/**
 * The Cart Summary
 */
.cart-summary {
    margin-left: 24px;
    width: 340px;
    flex-grow: 0;
    flex-shrink: 0;
}
.cart-summary h2 {
    margin: 0;
    padding: 16px;
    font-size: 24px;
    font-weight: 300;
    border-bottom: 1px solid var(--border-color);
}
.cart-summary-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    font-size: 24px;
    font-weight: 200;
    text-align: right;
    border-bottom: 1px solid var(--border-color);
}
.cart-summary-item h4 {
    margin: 0 16px 0 0;
    font-size: 13px;
    text-align: left;
    color: var(--gray-color);
}
.cart-summary-item p {
    margin: 0;
    white-space: nowrap;
}
.cart-summary-item img {
    max-width: 80px;
}
.cart-summary-image {
    width: 80px;
    height: 77px;
    background-size: contain;
    background-repeat: no-repeat;
}

.cart-summary-shipping p {
    white-space: normal;
}
.cart-summary-map {
    padding: 24px;
    border-bottom: 1px solid var(--border-color);
}
.cart-summary-map img {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.cart-summary-footer {
    padding: 16px;
}

.cart-summary-legals {
    margin-top: 24px;
    font-size: 12px;
    color: var(--gray-color);
}
.cart-summary-legals p {
    margin: 0;
    padding: 8px 16px;
    border-bottom: 1px solid var(--border-color);
}
.cart-summary-legals p:first-child {
    padding-top: 10px;
}



/**
 * Cart Address Select
 */
.cart-address-dialog {
    max-width: 450px;
}
.cart-address-dialog h3 {
    padding: 32px;
    text-align: center;
}

.cart-address-select {
    width: 100%;
    cursor: pointer;
}

.cart-address-alert {
    box-sizing: border-box;
    width: 100%;
    padding: 16px 8px 0 16px;
    border-bottom: 1px solid var(--border-color);
}
.cart-address-select > div:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
    padding: 24px;
}
.cart-address-selected {
    background-color: var(--lighter-color);
}



/**
 * Cart Completed
 */
.cart-completed header,
.cart-completed footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cart-completed header {
    border-bottom: 1px solid var(--border-color);
}

.cart-completed header h3 {
    margin-top: 0;
    font-size: 24px;
    color: var(--green-color);
}
.cart-completed header h4 {
    margin: 0;
    font-size: 16px;
}

.cart-completed footer h4 {
    margin-top: 0;
    font-size: 16px;
}
.cart-completed footer a {
    margin-top: 16px;
}



/**
 * Cart Nothing
 */
.cart-none {
    text-align: center;
}
.cart-none h3 {
    margin-top: 0;
}




/**
 * Media Queries
 */
@media screen and (max-width: 1100px) {
    .cart-table tbody tr {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
        padding: 16px;
    }
    .cart-table tbody td {
        padding: 0 !important;
    }
    .cart-table tbody td:nth-child(2) {
        grid-column-end: span 2;
    }
    .cart-image {
        margin: 0 auto;
    }
    .cart-table .number-field {
        width: 120px;
    }
    .cart-column {
        min-height: 0;
    }
}
@media screen and (max-width: 950px) {
    .cart-container {
        flex-direction: column;
    }
    .cart-summary {
        margin-top: 64px;
        margin-left: 0;
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .cart-shipping {
        flex-direction: column;
    }
    .cart-shipping li:not(:last-child) {
        margin-bottom: 16px;
    }
}
@media screen and (max-width: 500px) {
    .cart-units {
        padding: 16px;
    }
    .cart-product h3 {
        font-size: 14px;
    }
    .cart-price.cart-price,
    .cart-price.cart-price .product-new-value {
        font-size: 20px;
    }
    .cart-price .product-price-old {
        font-size: 14px;
    }

    .cart-address-content {
        flex-direction: column;
        align-items: flex-start;
    }
    .cart-address-content .icon {
        display: none;
    }
    .cart-address-content a {
        margin-top: 16px;
    }
}
@media screen and (max-width: 400px) {
    .cart-table tbody tr {
        grid-template-columns: repeat(2, 1fr);
    }
    .cart-table tbody td:nth-child(2) {
        order: 1;
    }
    .cart-table tbody td:nth-child(1) {
        order: 2;
    }
    .cart-table tbody td:nth-child(3) {
        order: 3;
    }
    .cart-table tbody td:nth-child(4) {
        order: 4;
    }
    .cart-table tbody td:nth-child(5) {
        order: 5;
    }
    .cart-product,
    .cart-price .product-price-old {
        text-align: center;
    }
}
