/**
 * The Main Menu
 */
.menu-container {
    background-color: var(--accent-color);
    color: white;
    font-size: 14px;
}
.menu-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.menu-nav {
    display: flex;
    color: var(--white-color);
}
.menu-nav li {
    display: flex;
    height: 50px;
    padding: 0;
    justify-content: center;
    align-items: center;
}
.menu-nav li a {
    --link-color: var(--white-color);
    font-weight: 400;
}
.menu-nav .menu-user {
    padding-right: 8px;
}
.menu-nav .icon:not(.icon-down) {
    color: var(--white-color);
}
.menu-nav .icon-down {
    position: relative;
    top: 3px;
    left: 3px;
    color: rgba(255, 255, 255, 0.5);
}


.menu-primary {
    flex-grow: 2;
}
.menu-primary li {
    padding: 0;
}

.menu-secondary.menu-secondary {
    margin-right: 40px;
}
.menu-dollar {
    margin-right: 16px;
}


.menu-terciary {
    color: white;
}
.menu-terciary li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-terciary li:last-child {
    padding-right: 0;
}
.menu-terciary .icon:not(.icon-down) {
    font-size: 18px;
    line-height: 18px;
}



/**
 * The Sub Menu
 */
.submenu-container {
    position: absolute;
    box-sizing: border-box;
    display: none;
    font-size: 13px;
    font-weight: 200;
    z-index: var(--z-submenu);
}
.submenu-open.submenu-open {
    display: flex;
}
@media screen and (max-width: 1248px) {
    .submenu-container {
        left: 24px;
        right: 24px;
        width: auto;
    }
}

.submenu-full {
    width: 1200px;
    max-width: calc(100% - 48px);
    flex-direction: column;
    padding: 32px;
    background-color: white;
}

.submenu-container:before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: -8px;
    border: 0 solid transparent;
    border-right-width: 16px;
    border-left-width: 16px;
    pointer-events: none;
}
    .submenu-category:before {
        left: 76px;
        border-bottom: 16px solid var(--primary-color);
    }
    .submenu-offer:before {
        left: 162px;
        border-bottom: 16px solid white;
    }
    .submenu-bike:before {
        left: 242px;
        border-bottom: 16px solid white;
    }
    .submenu-brand:before {
        left: 252px;
        border-bottom: 16px solid white;
    }

.submenu-button {
    padding: 8px 16px;
}



/**
 * Category Menu
 */
.submenu-category-list.submenu-category-list {
    width: 240px;
    padding: 16px 0;
    background-color: var(--primary-color);
}

.submenu-category-list li a {
    position: relative;
    color: #fff;
    display: block;
    padding: 0 36px;
    line-height: 2.59;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    cursor: pointer;
}
.submenu-category-list li a:after {
    position: absolute;
    top: 50%;
    right: 24px;
    content: "\e906";
    font-family: "icomoon";
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    transform: translateY(-50%);
}
.submenu-selected {
    background-color: var(--accent-color);
}


.submenu-category-subs {
    flex-grow: 2;
    padding: 32px;
    background-color: white;
    background-repeat: no-repeat;
    background-position: bottom right;
}
.submenu-category-big {
    width: 1200px;
}
.submenu-category-small {
    max-width: 550px;
}


.submenu-category-subs h2 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 16px;
    font-size: 20px;
    color: var(--accent-color);
    font-weight: 400;
    line-height: 1;
    border-bottom: 1px solid var(--border-color);
}
.submenu-category-subs ul {
    height: calc(100% - 50px);
    column-count: 3;
    column-width: 250px;
    column-fill: auto;
}
.submenu-category-subs ul li {
    font-size: 16px;
    font-weight: 200;
    margin-left: 16px;
    margin-bottom: 24px;
}
.submenu-category-small ul {
    column-count: 3;
}


@media screen and (max-width: 1264px) {
    .submenu-category-big,
    .submenu-category-small {
        width: calc(100% - 48px);
        max-width: none;
    }
    .submenu-category-subs ul {
        column-count: 2;
        column-width: auto;
    }
    .submenu-category-subs ul li {
        margin-left: 8px;
        margin-bottom: 8px;
    }
}



/**
 * Grid Menu
 */
.submenu-grid.submenu-grid a {
    position: relative;
    display: block;
    text-decoration: none;
    transition: all 0.5s;
    color: var(--primary-color);
}
.submenu-more {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    margin-top: 8px;
}



/**
 * Offer Menu
 */
.submenu-offer-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
}
.submenu-offer-grid li {
    transition: all 0.2s;
}
.submenu-offer-grid li:hover {
    transform: scale(1.1);
}
.submenu-offer-grid h2 {
    font-size: 15px;
    color: var(--primary-color);
    text-align: center;
    margin: 0;
    padding: 8px;
}
.submenu-offer-grid img {
    display: block;
    width: 100%;
}



/**
 * Bike Menu
 */
.submenu-bike-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px 32px;
}
.submenu-bike-grid header {
    position: absolute;
    top: 0px;
    left: 8px;
    z-index: 1;
}
.submenu-bike-grid h2 {
    margin: 0;
    font-size: 16px;
    color: var(--primary-color);
}
.submenu-bike-grid h3 {
    margin: 0;
    font-size: 13px;
    color: var(--gray-color);
    text-transform: uppercase;
}
.submenu-bike-grid img {
    display: block;
    width: 95%;
}



/**
 * Brand Menu
 */
.submenu-brand-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 12px;
}
.submenu-brand-grid li:nth-child(-n+6) {
    grid-column-end: span 2;
    grid-row-end: span 2;
}
@media screen and (max-width: 900px) {
    .submenu-brand-grid {
        grid-template-columns: repeat(10, 1fr);
    }
}

.submenu-brand-grid a {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 4px;
    border: 1px solid var(--border-color);
    background-color: white;
    transition: all 0.2s;
}
.submenu-brand-grid a:hover {
    transform: scale(1.2);
    z-index: 2;
}

.submenu-brand-grid h2 {
    font-size: 14px;
    text-align: center;
}
.submenu-brand-grid img {
    display: block;
    width: 100%;
}



/**
 * Secondary Menus
 */
.submenu-secondary {
    display: none;
    position: absolute;
    box-sizing: border-box;
    flex-direction: column;
    background-color: white;
    color: var(--black-color);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: var(--z-submenu);
}
.submenu-secondary:before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: -8px;
    border: 0 solid transparent;
    border-right-width: 16px;
    border-left-width: 16px;
    border-bottom: 16px solid white;
    pointer-events: none;
}
    .submenu-client:before {
        right: 102px;
    }
    .submenu-favorite:before {
        right: 64px;
    }
    .submenu-notification:before {
        right: 32px;
    }




/**
 * Client Menu
 */
.submenu-client {
    width: 250px;
    margin-left: 950px;
    padding: 16px 0;
}
.submenu-client a {
    display: block;
    padding: 10px 24px;
    font-size: 14px;
    line-height: 1.25;
    color: var(--black-color);
}
.submenu-client a:hover {
    background-color: var(--lightest-color);
}



/**
 * Favorites Menu
 */
.submenu-favorite {
    width: 400px;
    margin-left: 800px;
}
.submenu-favorite > h3 {
    margin: 0;
    padding: 8px 16px;
    font-size: 14px;
    border-bottom: 1px solid var(--border-color);
}
.submenu-favorite ul {
    display: flex;
    flex-direction: column;
}
.submenu-favorite ul li {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid var(--border-color);
    background-color: white;
    transition: all 0.3s linear;
    cursor: pointer;
}
.submenu-favorite ul li:hover {
    background-color: var(--lightest-color);
}
.submenu-favorite ul li:hover .submenu-favorite-delete {
    opacity: 1;
}

.submenu-favorite-image {
    box-sizing: border-box;
    height: 60px;
    width: 60px;
    overflow: hidden;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
}
.submenu-favorite-image img {
    max-width: 60px;
    max-height: 60px;
}
.submenu-favorite-product {
    padding-left: 24px;
}
.submenu-favorite-product h4 {
    margin: 0;
    font-size: 14px;
}
.submenu-favorite-product h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 200;
}

.submenu-favorite-delete {
    position: absolute;
    top: 8px;
    right: 16px;
    opacity: 0;
    font-size: 12px;
    background-color: var(--lightest-color);
    box-shadow: -10px 6px 22px 10px var(--lightest-color);
    transition: all 0.3s;
    z-index: 1;
}



/**
 * Notification Menu
 */
.submenu-notification {
    width: 400px;
    margin-left: 800px;
}
.submenu-notification > h3 {
    margin: 0;
    padding: 8px 16px;
    font-size: 14px;
    border-bottom: 1px solid var(--border-color);
}
.submenu-notification ul {
    display: flex;
    flex-direction: column;
}




/**
 * Media Queries
 */
@media screen and (max-width: 1250px) {
    .submenu-secondary {
        right: 24px;
    }
}

@media screen and (max-width: 1050px) {
    .submenu-bike-grid header {
        top: -24px;
    }
    .submenu-bike-grid img {
        margin-top: 24px;
    }
}
@media screen and (max-width: 950px) {
    .menu-container {
        display: none;
    }
}
