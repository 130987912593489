/**
 * Client Banks
 */
.banks-content {
    position: relative;
    background-image: url(../../Images/Banks/Background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.banks-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 210px;
    width: 100%;
    max-width: 1400px;
    background-image: url(../../Images/Banks/Header.png);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
}
.banks-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 12px;
    padding: 140px 32px 32px 32px;
}

.banks-bank {
    display: flex;
    align-items: center;
}
.banks-bank img {
    width: 140px;
    margin-right: 24px;
    opacity: 0.9;
    mix-blend-mode: darken;
}
.banks-bank h3 {
    margin: 0 0 8px 0;
}
.banks-bank p {
    margin: 0 0 4px 0;
}
.banks-bank p:last-child {
    margin: 0;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1000px) {
    .banks-image {
        height: 150px;
        background-size: contain;
    }
    .banks-grid {
        display: block;
        margin: 0 auto;
        max-width: 450px;
    }
    .banks-bank:first-child {
        display: none;
    }
    .banks-bank {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 950px) {
    .banks-image {
        height: 200px;
    }
}

@media screen and (max-width: 700px) {
    .banks-image {
        height: 150px;
        background-size: cover;
    }
}

@media screen and (max-width: 500px) {
    .banks-grid {
        padding: 150px 16px 32px 16px;
    }
}

@media screen and (max-width: 400px) {
    .banks-image {
        height: 120px;
    }
    .banks-bank {
        flex-direction: column;
        margin-bottom: 48px;
    }
    .banks-bank:last-child {
        margin-bottom: 0;
    }
    .banks-bank img {
        margin-right: 0;
        margin-bottom: 16px;
    }
    .banks-grid {
        padding-top: 130px;
    }
}
