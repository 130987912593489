/**
 * The Content
 */
.content-main {
    position: relative;
    min-height: var(--full-height);
}
.content-backdrop:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: var(--z-backdrop);
}



/**
 * Media Queries
 */
@media screen and (max-width: 750px) {
    .content-main {
        padding-top: var(--header-height);
    }
}
