/**
 * The Animations
 */
@keyframes backdrop-open {
    from { opacity: 0; }
    50%  { opacity: 1; }
}
@keyframes dialog-open {
    from { opacity: 0; transform: scale(0.8); }
    to   { opacity: 1; transform: scale(1);   }
}

@keyframes backdrop-close {
    from { opacity: 1; }
    to   { opacity: 0; }
}
@keyframes dialog-close {
    from { opacity: 1; transform: scale(1);   }
    to   { opacity: 0; transform: scale(0.8); }
}



/**
 * The Dialog
 */
.dialog-noscroll {
    overflow: hidden;
}
.dialog-backdrop {
    display: none;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-dialog);
    animation: backdrop-open 0.2s ease-out;
}
.dialog-container {
    --dialog-top: 0px;
    --dialog-bottom: 0px;
    --dialog-header: calc(var(--dialog-top) + var(--line-height));
    --dialog-footer: 0px;
    --dialog-spacing: 32px;

    margin: var(--dialog-spacing);
    width: calc(100% - var(--dialog-spacing) * 2);
    max-height: calc(100vh - var(--dialog-spacing) * 2);
    max-width: 600px;
    overflow: auto;
    background-color: white;
    animation: dialog-open 0.2s ease-out;
}
.dialog-with-footer .dialog-container {
    --dialog-footer: calc(var(--dialog-bottom) + var(--line-height));
}
.dialog-image {
    max-width: 800px;
}


.dialog-open {
    display: flex;
}
.dialog-closing {
    display: flex;
    animation: backdrop-close 0.2s linear;
    opacity: 0;
}
.dialog-closing .dialog-container {
    animation: dialog-close 0.2s ease-out;
}



/**
 * The Dialog Header
 */
.dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;
    background-color: var(--primary-color);
    color: white;
    border-bottom: none;
    height: var(--dialog-header);
}
.dialog-close {
    color: white;
}



/**
 * The Dialog Content
 */
.dialog-content {
    box-sizing: border-box;
    overflow: auto;
    flex-grow: 2;
    max-height: calc(100vh - var(--dialog-spacing) * 2 - var(--dialog-header) - var(--dialog-footer));
}

.dialog-delete {
    margin: 0;
    font-weight: 400;
}

.dialog-image .dialog-content {
    display: block;
    overflow: auto;
    padding: 0;
}
.dialog-image .dialog-content img {
    box-sizing: border-box;
    display: block;
    width: 100%;
    min-width: 500px;
    padding: 32px;
}



/**
 * The Dialog Footer
 */
.dialog-footer {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--dialog-footer);
    padding: 0 32px;
    border-top: 1px solid var(--border-color);
}



/**
 * Media Queries
 */
@media screen and (max-width: 500px) {
    .dialog-container.dialog-container {
        --dialog-spacing: 0;
        width: 100%;
        height: 100vh;
        max-width: none;
        max-height: none;
    }
    .dialog-header {
        padding-top: var(--dialog-top);
        padding-right: 8px;
        padding-left: 8px;
    }
    .dialog-header h3 {
        padding: 0;
        font-size: 18px;
        line-height: 1;
    }
    .dialog-content {
        height: calc(100vh - var(--dialog-header) - var(--dialog-footer));
        max-height: none;
    }
    .dialog-image .dialog-content img {
        padding: 16px;
    }
    .dialog-footer {
        padding-bottom: var(--dialog-bottom);
        padding-right: 16px;
        padding-left: 16px;
    }
}
