/**
 * Product
 */
.product-container {
    position: relative;
    height: 410px;
}
.product-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 100%;
    max-width: 280px;
    background-color: var(--white-color);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
.product-card-favorite {
    position: absolute;
    top: 4px;
    right: 4px;
}


.product-code {
    text-align: center;
    padding: 12px 0 6px 0;
    font-weight: bold;
}
.product-description {
    box-sizing: border-box;
    flex-shrink: 0;
    height: 65px;
    padding: 0 16px;
    color: var(--gray-color);
    font-size: 12px;
}
.product-button {
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}



/**
 * Product Image
 */
.product-card-image {
    height: 250px;
    background-color: white;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    overflow: hidden;
}



/**
 * Product Price
 */
.product-card-price {
    height: 55px;
    padding: 8px 16px 0;
    font-size: 20px;
}



/**
 * Hover Effect
 */
.product-container:hover {
    z-index: 1;
}
.product-container:hover .product-card {
    box-shadow: 0 25px 20px 0 rgba(0, 0, 0, 0.16), 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
}
.product-container:hover .slider-nav button {
    opacity: 0.5;
}
.product-container:hover .product-button {
    height: 38px;
}



/**
 * The Hover
 */
.product-hover {
    height: 350px;
    margin-bottom: 32px;
}
.product-hover .product-description {
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}
.product-hover:hover .product-description {
    height: 65px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 750px) {
    .product-container {
        height: auto;
    }
    .product-card {
        height: 100%;
    }
    .product-card-image,
    .product-card-image .slider-container {
        height: 200px;
    }
    .product-card-price {
        height: auto;
    }
    .product-price-new {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .product-discount {
        padding: 0;
    }
    .product-description {
        padding: 8px 16px 16px 16px;
        height: auto;
    }
    .product-button {
        display: none;
    }

    .product-container:hover .product-card {
        box-shadow: none;
    }
}

@media screen and (max-width: 500px) {
    .product-card-image,
    .product-card-image .slider-container {
        height: 150px;
    }
    .product-image > h3 {
        padding: 0 8px;
    }
    .product-card-price {
        padding: 12px 12px 0 12px;
    }
    .product-description {
        padding: 12px;
        font-size: 11px;
    }
}

@media screen and (max-width: 400px) {
    .product-code {
        padding-left: 12px;
        text-align: left;
    }
}

@media screen and (max-width: 350px) {
    .product-code {
        padding-left: 8px;
    }
    .product-card-favorite {
        right: 0;
    }
}

@media screen and (max-width: 300px) {
    .product-code {
        font-size: 13px;
    }
}
