/**
 * The Offers Header
 */
.offers-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 230px;
    background-image: url(../../Images/Store/Offers.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.offers-header h2 {
    margin: 0;
    position: relative;
    z-index: 1;
    color: white;
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 5px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}
.offers-header:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
}



/**
 * The Offers Banner
 */
.offers-banners {
    margin-bottom: 32px;
}



/**
 * The Offers Content
 */
.offers-content.offers-content {
    margin: 32px 0 0 0;
}
.offers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 24px;
}
.offers-grid li {
    transition: all 0.2s;
}
.offers-grid li:hover {
    transform: scale(1.1);
}
.offers-grid img {
    display: block;
    width: 100%;
}
.offers-grid h2 {
    font-size: 15px;
    color: var(--primary-color);
    text-align: center;
    margin: 0;
    padding: 8px;
}


.offers-none {
    margin-top: 0;
}



/**
 * Media Queries
 */
@media screen and (max-width: 750px) {
    .offers-grid li:hover {
        transform: none;
    }
}

@media screen and (max-width: 600px) {
    .offers-header {
        height: 180px;
    }
    .offers-header h2 {
        font-size: 32px;
    }
}
@media screen and (max-width: 400px) {
    .offers-grid {
        grid-template-columns: 1fr;
    }
}
