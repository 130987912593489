/**
 * The Home Banner
 */
.banner-card {
    display: flex;
    background-color: var(--white-color);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
.banner-primary {
    --banner-color: var(--accent-color);
}
.banner-secondary {
    margin-top: 40px;
    --banner-color: var(--subaccent-color);
}



/**
 * The Banner Header
 */
.banner-header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px;
}
.banner-title h2,
.banner-title h3 {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 8px 12px;
    line-height: 1;
    text-transform: uppercase;
    color: white;
    font-size: 44px;
    background-color: var(--banner-color);
}
.banner-title h2 {
    padding-bottom: 0;
    font-weight: 200;
    z-index: 1;
}
.banner-title h3 {
    margin-top: -4px;
    font-weight: 800;
}



/**
 * The Banner Timer
 */
.banner-timer {
    display: flex;
    align-items: center;
    margin-top: 24px;
}
.banner-time-numbers {
    display: flex;
}
.banner-time-numbers div {
    padding: 4px 10px;
    margin: 0 2px;
    font-size: 24px;
    color: white;
    background-color: var(--primary-color);
}
.banner-time-name {
    margin-top: 4px;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
}
.banner-time-dots {
    margin: 0 4px 20px;
    font-size: 24px;
    line-height: 1;
}



/**
 * The Banner Content
 */
.banner-content {
    display: flex;
    align-items: center;
    --slider-size: 300px;
}

.banner-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--slider-size);
    height: var(--slider-size);
    background-color: white;
}
.banner-image {
    box-sizing: border-box;
    height: auto;
    width: var(--slider-size);
    height: var(--slider-size);
    padding: 0 16px;
    cursor: pointer;
}



/**
 * The Banner Product
 */
.banner-product {
    padding: 32px;
}
.banner-product h2 {
    color: var(--light-color);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 0;
    text-align: center;
}
.banner-price {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.banner-price div {
    flex-shrink: 0;
}
    .banner-price > div:first-child {
        box-sizing: border-box;
        width: 40%;
        text-align: right;
        padding-right: 24px;
        font-weight: bold;
        font-size: 18px;
        color: var(--gray-color);
    }
    .banner-price > div:last-child {
        width: 60%;
    }


.banner-price-value {
    font-size: 32px;
}
.banner-price-value .product-price-old {
    font-size: 0.5em;
}
.banner-price-value .product-discount {
    font-size: 0.6em;
    padding-left: 16px;
}


.banner-show {
    text-align: right;
}


/**
 * Media Queries
 */
@media screen and (max-width: 1150px) {
    .banner-title h2,
    .banner-title h3 {
        font-size: 32px;
    }
    .timer-container {
        font-size: 16px;
    }
    .timer-numbers div {
        padding: 4px 6px;
    }
    .timer-name {
        font-size: 9px;
    }
}
@media screen and (max-width: 1000px) {
    .banner-card {
        flex-direction: column;
    }
    .banner-header {
        flex-direction: row;
        justify-content: space-around;
        padding: 16px;
    }
    .banner-content {
        --slider-size: 250px;
    }
    .banner-product {
        padding: 16px;
    }
    .banner-product h2,
    .banner-price {
        margin-bottom: 16px;
    }
}
@media screen and (max-width: 800px) {
    .banner-product h2,
    .banner-price > div:first-child {
        font-size: 16px;
    }
}
@media screen and (max-width: 700px) {
    .banner-price .product-price-new {
        flex-direction: column;
        align-items: flex-start;
    }
    .banner-price .product-new-value {
        font-size: 24px;
    }
    .banner-price .product-price-new .product-price-symbol {
        margin-right: 0;
    }
    .banner-price > div:first-child,
    .banner-price > div:last-child {
        width: auto;
    }
    .banner-price > div:first-child {
        flex-shrink: 1;
    }
}
@media screen and (max-width: 600px) {
    .banner-title h3 {
        font-size: 30px;
    }
    .timer-numbers div {
        margin: 0 1px;
    }
    .banner-content {
        --slider-size: 200px;
    }
    .banner-price {
        flex-direction: column;
    }
    .banner-price > div:first-child {
        padding-right: 0;
        padding-bottom: 8px;
    }
    .banner-price .product-price-new {
        flex-direction: row;
    }
}
@media screen and (max-width: 500px) {
    .banner-header,
    .banner-content {
        flex-direction: column;
        align-items: center;
    }
    .banner-slider {
        display: none;
        width: 100%;
    }
    .timer-container {
        margin-top: 8px;
    }
}
