/**
 * The Terms Container
 */
.terms-container h1 {
    position: fixed;
    padding: 30px 24px;
    margin: 0;
}
.terms-container h1 img {
    height: 30px;
}



/**
 * The Terms Wrapper
 */
.terms-wrapper {
    position: relative;
    padding: 24px 24px 24px 240px;
}
.terms-wrapper h2 {
    margin: 0;
    font-size: 32px;
    line-height: 1;
}
.terms-wrapper a {
    display: block;
}



/**
 * The Terms Content
 */
 .terms-content {
    line-height: 2;
}
.terms-content p:first-child {
    margin-top: 0;
}
.terms-content ol {
    counter-reset: item;
    margin: 0;
    padding-left: 0;
}
.terms-content ol > li > ol {
    margin-top: 16px;
}
.terms-content ol > li {
    position: relative;
    display: block;
    margin-left: 28px;
}
.terms-content ol > li > ol > li {
    margin-left: 40px;
}
.terms-content ol > li:not(:last-child) {
    margin-bottom: 16px;
}

.terms-content ol > li:before {
    position: absolute;
    left: -28px;
    font-weight: bold;
    content: counters(item, ".") ". ";
    counter-increment: item;
}
.terms-content ol > li > ol > li:before {
    left: -40px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 800px) {
    .terms-container h1 {
        position: static;
        padding: 24px;
    }
    .terms-wrapper {
        padding: 0 24px 24px 24px;
    }
}

@media screen and (max-width: 500px) {
    .terms-wrapper {
        padding-left: 16px;
        padding-right: 16px;
    }
    .terms-container h1 {
        padding: 16px;
    }
    .terms-wrapper h2 {
        font-size: 28px;
    }
}

@media screen and (max-width: 400px) {
    .terms-wrapper {
        padding-left: 8px;
        padding-right: 8px;
    }
}
