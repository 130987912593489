/**
 * The Loader classes
 */
.loader {
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--z-loader);
}
.loader-auth {
    background-color: rgba(245, 245, 245, 0.8);
}
.loader-app {
    top: var(--header-height);
    background-color: rgba(245, 245, 245, 1);
}
.loader-show {
    display: flex;
}


.loader-image {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader img {
    width: 150px;
}
.loader-text {
    color: rgb(170, 170, 170);
    padding-bottom: 80px;
}
