/**
 * The Products Content
 */
.products-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.products-no-header {
    padding-top: 40px;
    padding-bottom: 40px;
}



/**
 * The Products Nothing
 */
.products-none {
    max-width: 600px;
    text-align: center;
}



/**
 * Media Queries
 */
@media screen and (max-width: 800px) {
    .products-wrapper {
        flex-direction: column;
        padding: 0;
    }
}
