/**
 * The Aside Header
 */
.aside {
    box-sizing: border-box;
    flex-grow: 1;
    max-width: 270px;
    min-width: 200px;
    margin-right: 52px;
    color: var(--gray-color);
    font-size: 14px;
    line-height: 1.29;
    font-weight: 300;
}
.aside-with-header {
    padding-top: 88px;
}

.aside-header {
    margin-bottom: 52px;
}
.aside-header h3 {
    display: block;
    font-size: 24px;
    font-weight: 200;
    line-height: 30px;
    margin: 24px 0 8px 0;
    position: relative;
    word-wrap: break-word;
    color: var(--black-color);
}
.aside-results {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: var(--light-color);
}
.aside-filter {
    display: none;
}



/**
 * The Aside Options
 */
.aside-subtitle {
    display: none;
}
.aside-subtitle button {
    appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: var(--accent-color);
    font-size: 24px;
    cursor: pointer;
    outline: none;
}


.aside-options {
    margin-bottom: 32px;
}
.aside-option.aside-option {
    margin: 0;
}
.aside-options h4 {
    color: var(--black-color);
    font-weight: 700;
    font-size: 14px;
    margin: 0 0 12px;
}
.aside-options li {
    padding-bottom: 8px;
}
.aside-selected {
    font-weight: bold;
}
.aside-amount {
    font-size: 12px;
    margin-left: 4px;
}
.link:not(.link-red) > .link-content > .aside-amount {
    color: var(--light-color);
}



/**
 * Media Queries
 */
@media screen and (max-width: 800px) {
    .aside {
        width: 100%;
        max-width: none;
        margin: 0;
    }
    .aside-with-header {
        padding-top: 0;
    }
    .aside-header {
        padding: 16px 32px;
        background-color: var(--white-color);
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 16px;
        margin-right: 0;
    }
    .aside-header h3 {
        font-size: 22px;
        margin-top: 16px;
    }
    .aside-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .aside-filter {
        display: block;
        font-weight: 400;
    }

    .aside-content {
        display: none;
    }
    .aside-open {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: var(--z-aside);
        overflow: auto;
    }
    .aside-subtitle {
        display: flex;
        background-color: var(--primary-color);
        color: white;
    }
    .aside-subtitle .link-icon {
        color: white;
    }
    .aside-filters {
        padding: 32px;
    }
}

@media screen and (max-width: 600px) {
    .aside-header h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 500px) {
    .aside-header {
        padding: 16px;
        margin-bottom: 8px;
    }
    .aside-header h3 {
        font-size: 18px;
    }
}
