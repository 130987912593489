/**
 * The Links
 */
.link {
    position: relative;
    color: var(--link-color);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}
    .link:hover,
    .link:focus {
        outline: none;
    }
    .link:not(.link-menu):not(.link-image):not(.link-icon):before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.2s ease-in-out;
        background-color: var(--link-color);
    }
    .link:not(.link-menu):not(.link-image):not(.link-icon):hover:before,
    .link:not(.link-menu):not(.link-image):not(.link-icon):focus:before {
        visibility: visible;
        transform: scaleX(1);
    }

.link-with .link-preicon {
    font-size: 1.2em;
    margin-right: 12px;
}



/**
 * Colored Links
 */
.link-primary {
    --link-color: var(--accent-color);
}
.link-secondary {
    --link-color: var(--subaccent-color);
}
.link-black {
    --link-color: var(--black-color);
}
.link-white {
    --link-color: white;
    font-weight: 200;
}
.link-gray {
    --link-color: var(--gray-color);
    font-weight: 400;
}
.link-light {
    --link-color: var(--light-color);
    font-weight: 400;
}
.link-red {
    --link-color: var(--red-color);
    font-weight: 600;
}
.link-green {
    --link-color: var(--green-color);
    font-weight: 600;
}
.link-greenblue {
    --link-color: rgb(134, 195, 188);
    font-weight: 600;
}



/**
 * The Menu Icon
 */
.link-menu {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 9999px;
}
.link-menu:hover {
    background-color: rgba(0, 0, 0, 0.1);
}



/**
 * The Image Link
 */
.link-image {
    display: block;
    transition: all 0.2s ease-in-out;
}
.link-image:hover {
    filter: grayscale(1);
    color: #666;
}

.link-opacity {
    display: block;
    transition: all 0.2s ease-in-out;
}
.link-opacity:hover {
    opacity: 0.5;
}



/**
 * The Icon Link
 */
.link-icon {
    --link-color: var(--accent-color);
    display: block;
    width: 32px;
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
}
.link-icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
}



/**
 * The Badge
 */
.badge {
    position: absolute;
    top: -2px;
    right: 2px;
    min-width: 14px;
    line-height: 14px;
    font-size: 10px;
    text-align: center;
    border-radius: 999px;
    background-color: var(--white-color);
    color: var(--primary-color);
}
