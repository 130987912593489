/**
 * The Products Header
 */
.products-header {
    position: relative;
    height: 180px;
    background-image: url(../../../Images/Store/Products.jpg);
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.cylinders-header {
    background-image: url(../../../Images/Store/Bikes.jpg);
}

.products-logo {
    display: flex;
    position: relative;
    top: 110px;
    width: 130px;
    height: 130px;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border: 1px solid var(--border-color);
    background-color: white;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1);
    transition: all 0.2s;
    z-index: 1;
}
.products-logo:hover {
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, .2), 0 1px 3px 0 rgba(0, 0, 0, .1);
}
.products-logo h2 {
    font-size: 14px;
    text-align: center;
}
.products-logo img {
    display: block;
    width: 100%;
}

.products-offer {
    height: 240px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 950px) {
    .products-offer {
        height: 200px;
    }
}

@media screen and (max-width: 800px) {
    .products-logo {
        top: 20px;
    }
    .products-offer {
        height: 150px;
    }
}

@media screen and (max-width: 600px) {
    .products-offer {
        height: 200px;
    }
}

@media screen and (max-width: 400px) {
    .products-offer {
        height: 150px;
    }
}
